import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";
//import Moralis from "moralis";
import { useLocation, Navigate } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import Dropzone from "react-dropzone";
import $ from "jquery";
import axios from "axios";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import ModalImage from "react-modal-image"
import TruncateEthAddress from "truncate-eth-address";

const qs = require("qs");
const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;
const formatOrdinals = functions.formatOrdinals;
const clearTable = functions.clearTable;
const scrollToHash = functions.scrollToHash;

const Schedule = () => {
  const [tablePage, setTablePage] = React.useState(1);

  const [disablePrev, setDisablePrev] = React.useState(true);

  const [disableNext, setDisableNext] = React.useState(false);
  
  async function fillTable() {
    if(document.getElementById("schedule")) {
      //scrollToHash();
    
      // Grab the team data of the user in question
      
        const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Standings.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=400&pg=${tablePage}`).then(async (result) => {
          // Then, if the user has any players, grab them and put them into the table.

          console.log(result);

          if(result.data.length < 6) {
            setDisableNext(true);
          } else {
            setDisableNext(false);
          }
          
          if(document.getElementById("scheduletable") && result.data.length > 0) {
              const table = document.getElementById("scheduletable");

              let row = 0;
              // For each player, create a table row and fill them with.
              for(let i = 0; i < result.data.length; i++) {
                  console.log("new row");
                  // Initialize the current player for brevity's sake.
                  let day = result.data[i];

                  row += 1;

                  // Initialize the new row, then-
                  let tr = document.createElement("tr");

                  // -make sure it has an ID we can refer to it by, then,-
                  tr.id = row.toString();

                  let t = table;

                  // -add it to the new table.
                  t.appendChild(tr);

                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                  let date = document.createElement("td");

                  date.setAttribute('style', 'white-space: pre;');

                  date.classList.add("align-middle");

                  date.textContent = day.gameDate;

                  let num = document.createElement("td");

                  num.setAttribute('style', 'white-space: pre;');

                  num.classList.add("align-middle");

                  num.textContent = day.compositeNumber;

                  // Then, now that everything's been initialized, add all the cells to the table row.
                  tr.appendChild(date);

                  tr.appendChild(num);
              }
          }
      
        })
      
      
    }
  }
  function decrement() {
    const val = tablePage;

    const newVal = tablePage - 1

    setTablePage(newVal);

    if(newVal - 1 < 1) {
        setDisablePrev(true);
    } else {
        setDisablePrev(false);
    }
  }

  function increment() {
    const val = tablePage;

    const newVal = tablePage + 1

    setTablePage(newVal);

    if(newVal - 1 < 1) {
        setDisablePrev(true);
    } else {
        setDisablePrev(false);
    }
  }

  // Runs on pageload if the user is authenticated. Does a lot of things: Fills in team name, team logo, alongside the team table itself.
  React.useEffect(async () => {
      //console.log('location', location.pathname); // { key: "3uu089" }
      // Fire whatever function you need.

      // If it can find the roster element
      // (this is necessary because if you're just flipping through pages quickly it will rapidly build up errors and freeze the app as it is not finding the element in question)
      
    clearTable("scheduletable");

    await fillTable()
    
  }, [tablePage]);

  return (
    <div className="background-image-div">
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">
        <div className="div-content mx-auto mt-5" id="schedule">
          <div className="table-responsive h-75">
            <table className="table table-light">
              <thead className="table-secondary">
                <tr className="table-dark"><th colSpan={6}><h2 style={{textAlign:"center",marginBottom:"0"}} className="tableHeader">Schedule</h2></th></tr>
                  <tr>
                    <th>Date</th>
                    <th>Composite #</th>
                  </tr>
              </thead>
              <tbody id="scheduletable">
                
              </tbody>
            </table>
            <div className="button-div" style={{width: "100%"}}>
                <button id="decrement" className="amount-button me-2 btn btn-primary" disabled={disablePrev} onClick={async () => { decrement() }}>Prev</button>
                <p style={{width: "fit-content"}}>Page {tablePage}</p>
                <button id="increment" className="amount-button ms-2 btn btn-primary" disabled={disableNext} onClick={async () => { increment() }}>Next</button>
            </div>
          </div>
          
        </div>

        <div className="pagebottom"></div>
      </div>
    </div>
  );
};


export default Schedule;