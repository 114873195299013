let fetchAsync = async function(query) {
    let response = await fetch(query);
  
    let data = await response.json();
  
    return data;
}

let authenticated = function(data) {
    //console.log(data);
    let retval = false;
    if(data.results.ERROR === undefined || data.results.ERROR === null) {
        retval = true;
    }
    else {
        if(data.results.ERROR != "user not authenticate") {
            retval = true;
        }
    }

    return retval;
}

let versionMatched = async function(env) {
    const ver = env.REACT_APP_VERSION

    let query = `${env.REACT_APP_BASEURLSERVICE}/index.php?type=201&apiKey=${env.REACT_APP_INDEXAPIKEY}`;

    let retval = false;

    const a = await fetchAsync(query).then(async verRes => {
        console.log(verRes);
        if(verRes.data[0].currentBuild <= ver) {
            retval = true;
        }
    })

    return retval;
}

const pr = new Intl.PluralRules("en-US", { type: "ordinal" });

const suffixes = new Map([
  ["one", "st"],
  ["two", "nd"],
  ["few", "rd"],
  ["other", "th"],
]);
const formatOrdinals = (n) => {
  const rule = pr.select(n);
  const suffix = suffixes.get(rule);
  return `${n}${suffix}`;
};

const clearTable = async (id) => {
    const tableBody = document.getElementById(id);

    tableBody.innerHTML = "";
}

const scrollToHash = () => {

    /* Obtain hash from current location (and trim off leading #) */
    const id = window.location.hash.substr(1).split("#")[1];

    //console.log(id);

    if(id) {
        /* Find matching element by id */
        const anchor = document.getElementById(id);

        //console.log(anchor)

        if(anchor) {
            /* Scroll to that element if present */
            anchor.scrollIntoView();
        }
    }
}

export {authenticated,fetchAsync, versionMatched, formatOrdinals, clearTable, scrollToHash}