import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";
//import Moralis from "moralis";
import { useLocation, Navigate } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import Dropzone from "react-dropzone";
import $ from "jquery";
import axios from "axios";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import ModalImage from "react-modal-image"
import TruncateEthAddress from "truncate-eth-address";

const qs = require("qs");
const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;
const clearTable = functions.clearTable;

const Leaders = () => {
  const [showTeamModal,setShowTeamModal] = React.useState(false);

  const [teamObject, setTeamObject] = React.useState({id: 0});

  const [currentLeague, setCurrentLeague] = React.useState(1);

  const [currentLeagueName, setCurrentLeagueName] = React.useState("");

  const [showLeagueDropdown, setShowLeagueDropdown] = React.useState(false);

  const [showPitcherHeaders, setShowPitcherHeaders] = React.useState(false);

  const [showPlayerModal,setShowPlayerModal] = React.useState(false);

  const [showPitcherStats, setShowPitcherStats] = React.useState(false);

  const [playerObject, setPlayerObject] = React.useState({id: 0});

  const [statTitle, setStatTitle] = React.useState("Batting Average");

  const [statID, setStatID] = React.useState(1);

  const statNameArr = ["Batting Average","Home Runs","Runs","RBI","Stolen Bases","Earned Run Average","Wins","Innings Pitched","Strikeouts","Saves"];

  const location = useLocation();

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.session.currentUser);

  async function closeAllModals() {
    setShowTeamModal(false);

    setShowPlayerModal(false);
  }

  async function openPlayerModal(id, owner,role, playerName) {
    //console.log(id);

    await closeAllModals();

    const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Players.php?apiKey=${env.REACT_APP_PLAYERAPIKEY}&type=2&ei=${id}&oa=${owner}&ca=${env.REACT_APP_COLLECTIONADD}&hp=${role}`).then(async results => {
      const player = results.data;

      const totals = player.totals.data[0];

      //console.log(results);

      const newObject = {
        id: id,
        name: playerName,
        role: role,

        ba: ".000",
        homeRuns: 0,
        rbi: 0,
        runs: 0,
        stolenBases: 0,

        era: "0.00",
        inningsPitched: 0,
        strikeOuts: 0,
        saves: 0,
        wins: 0,

        gameLog: []
      };

      if(player.totals.data.length > 0) {
        if(role == 1) { // Hitter
          newObject.ba = totals.ba;
  
          if(newObject.ba == null) {
            newObject.ba = ".000";
          }
  
          newObject.homeRuns = totals.homeRuns;
  
          newObject.rbi = totals.rbi;
  
          newObject.runs = totals.runs;
  
          newObject.stolenBases = totals.stolenBases;
  
          setShowPitcherStats(false);
        } else { // Pitcher
          newObject.era = Number(totals.era).toFixed(2);
  
          if(totals.era == null) {
            newObject.era = ".00";
          }
  
          newObject.inningsPitched = totals.inningsPitched;
  
          newObject.saves = totals.saves;
  
          newObject.strikeOuts = totals.strikeOuts;
  
          newObject.wins = totals.wins;
  
          setShowPitcherStats(true);
        }
      }
      

      newObject.gameLog = player.gameLog.data
  
      setPlayerObject(newObject);
  
      setShowPlayerModal(true);
    }).catch(err => {
      console.log(err);
    })
    
  }

  async function fillTable() {
    if(document.getElementById("leagueleaders")) {
    
      // Grab the team data of the user in question
      
      const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Leagues.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=10&ca=${env.REACT_APP_COLLECTIONADD}&lid=${currentLeague}&st=${statID}&s=${env.REACT_APP_SEASON}`).then(async (result) => {
          // Then, if the user has any players, grab them and put them into the table.

          console.log(result);
          
          if(document.getElementById("leadertable") && result.data.length > 0) {
              const table = document.getElementById("leadertable");

              let row = 0;

              //let hitterArray = [];

              //let pitcherArray = [];

              /*for(let i = 0; i < result.data.length; i++) {
                  let team = result.data[i];
                  hitterArray.push(team);

                  pitcherArray.push(team);
              }

              hitterArray.sort(function(a,b) {return Number(b.battingAverage) - Number(a.battingAverage)});

              pitcherArray.sort(function(a,b) {return Number(a.earnedRunAverage) - Number(b.earnedRunAverage) });*/

              // For each player, create a table row and fill them with.
              for(let i = 0; i < result.data.length; i++) {
                  console.log("new row");
                  // Initialize the current player for brevity's sake.
                  let player = result.data[i];

                  //let hitter = hitterArray[i];

                  //let pitcher = pitcherArray[i];

                  row += 1;

                  let address = player.ownerAddress;

                  let name = player.playerName

                  let ownerName = player.teamName;

                  if(ownerName == address) {
                    ownerName = TruncateEthAddress(ownerName);
                  }

                  // Initialize the new row, then-
                  let tr = document.createElement("tr");

                  //let playerData = JSON.stringify(player);

                  // -make sure it has an ID we can refer to it by, then,-
                  tr.id = `${row.toString()}`;

                  let t = table;

                  // -add it to the new table.
                  t.appendChild(tr);

                  let playerImage = document.createElement("td");

                  playerImage.classList.add("imgcont","align-middle")

                  let img = <ModalImage
                          className={`rosterimg`}
                          small={`${player.editionURL}`}
                          medium={`${player.editionURL}`}
                      />
                  ReactDOM.render(img,playerImage)

                  let teamImage = document.createElement("td");

                  teamImage.classList.add("imgcont","align-middle")

                  let teamImg = <ModalImage
                          className={`rosterimg`}
                          small={`${player.teamLogo}`}
                          medium={`${player.teamLogo}`}
                      />
                  ReactDOM.render(teamImg,teamImage)

                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                  let playerCard = document.createElement("td");

                  playerCard.setAttribute('style', 'white-space: pre;');

                  playerCard.classList.add("align-middle");

                  let playerCardButton = document.createElement("button");

                  playerCardButton.classList.add("btn","px-0","py-0","playerButton");

                  playerCardButton.innerHTML = name;

                  playerCard.appendChild(playerCardButton);

                  let teamCard = document.createElement("td");

                  teamCard.setAttribute('style', 'white-space: pre;');

                  teamCard.classList.add("align-middle");

                  let teamCardButton = document.createElement("a");

                  teamCardButton.classList.add("btn","px-0","py-0","playerButton");

                  teamCardButton.href = `/#/teamstats/${address}`;
  
                  teamCardButton.innerHTML = ownerName;

                  teamCard.appendChild(teamCardButton);

                  tr.appendChild(playerImage);
  
                  tr.appendChild(playerCard);

                  tr.appendChild(teamCard);

                  if(statID <= 5) {
                    let roleService = 1;

                    playerCardButton.onclick = openPlayerModal.bind(null,player.editionID,address,roleService,player.playerName);

                    let ba = document.createElement("td");

                    ba.classList.add("align-middle");
  
                    ba.innerHTML = `${player.battingAverage}`
  
                    let sb = document.createElement("td");
  
                    sb.classList.add("align-middle");
  
                    sb.innerHTML = `${player.stolenBases}`
  
                    let rbi = document.createElement("td");
  
                    rbi.classList.add("align-middle");
  
                    rbi.innerHTML = `${player.rbi}`
  
                    let runs = document.createElement("td");
  
                    runs.classList.add("align-middle");
  
                    runs.innerHTML = `${player.runs}`
  
                    let hr = document.createElement("td");
  
                    hr.classList.add("align-middle");
  
                    hr.innerHTML = `${player.homeRuns}`;
  
                    tr.appendChild(ba);
  
                    tr.appendChild(hr);
  
                    tr.appendChild(runs);
  
                    tr.appendChild(rbi);
  
                    tr.appendChild(sb);
                  } else {
                    let roleService = 2;

                    playerCardButton.onclick = openPlayerModal.bind(null,player.editionID,address,roleService,player.playerName);

                    let so = document.createElement("td");

                    so.classList.add("align-middle");
  
                    so.innerHTML = `${player.strikeOuts}`
  
                    let ip = document.createElement("td");
  
                    ip.classList.add("align-middle");
  
                    ip.innerHTML = `${player.inningsPitched}`
  
                    let sv = document.createElement("td");
  
                    sv.classList.add("align-middle");
  
                    sv.innerHTML = `${player.saves}`
  
                    let era = document.createElement("td");
  
                    era.classList.add("align-middle");
  
                    era.innerHTML = `${player.earnedRunAverage.toFixed(2)}`
  
                    let wins = document.createElement("td");
  
                    wins.classList.add("align-middle");
  
                    wins.innerHTML = `${player.wins}`
  
                    tr.appendChild(era);
  
                    tr.appendChild(wins);
  
                    tr.appendChild(sv);
  
                    tr.appendChild(ip);
  
                    tr.appendChild(so);
                  }

                  
              }
          }
      
      })
    }
  }

  async function changeLeague(name, id) {
    setCurrentLeague(id);
    setCurrentLeagueName(name);

    let currentID = statID;

    console.log(`Setting to zero`)

    setStatID(0);

    console.log(`Set to zero, currentID = ${currentID}. Setting to currentID`);

    setStatID(currentID);

    console.log(statID);
  }

  // Runs on pageload if the user is authenticated. Does a lot of things: Fills in team name, team logo, alongside the team table itself.
  React.useEffect(async () => {
    const q = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Leagues.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=401&ca=${env.REACT_APP_COLLECTIONADD}`).then(async leagueRes => {
      console.log(leagueRes);
      let defaultLeague = 1;
      let defaultLeagueName = leagueRes.data[0].leagueName;
      if(leagueRes.data.length > 1) {
        setShowLeagueDropdown(true);
      } else {
        setCurrentLeagueName(defaultLeagueName);
        setCurrentLeague(defaultLeague);
        
      }
    })
    
  }, []);

  React.useEffect(async () => {
    if(showLeagueDropdown) {
      const q = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Leagues.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=401&ca=${env.REACT_APP_COLLECTIONADD}`).then(async leagueRes => {
        //console.log(leagueRes);
        const leagues = document.getElementById("leagueMenu");
        let defaultLeague = 1;
        let defaultLeagueName = leagueRes.data[0].leagueName;
        if(leagueRes.data.length > 1) {
          for(let i = 0; i < leagueRes.data.length; i++) {
            let league = leagueRes.data[i];
    
            let li = document.createElement("li");
    
            let button = document.createElement("a");
    
            button.classList.add("dropdown-item");
    
            li.appendChild(button);
    
            button.textContent = league.leagueName;
    
            button.onclick = changeLeague.bind(null,league.leagueName,league.leagueID);

            button.type = "button"

            leagues.appendChild(button);
          }
          
          if(currentUser) {
            console.log("logged in")
            const b = fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${currentUser}&c=${env.REACT_APP_COLLECTIONADD}`).then(async userRes => {
              defaultLeague = userRes.data[0].leagueID
            })
          }
        }
        setCurrentLeague(defaultLeague);
        setCurrentLeagueName(defaultLeagueName);
      })
    }
    
    
  }, [showLeagueDropdown]);

  React.useEffect(async () => {

    //console.log("Starting")
    
    if(document.getElementById("gameLogBody") && showPlayerModal) {
      //console.log("Found gamelogbody")
      const body = document.getElementById("gameLogBody");

      let remove = body.children;

      for(let i = 0; i < remove.length; i++) {
        body.removeChild(remove[i]);
      }
      //console.log("Removed prior children")

      const gamelog = playerObject.gameLog;

      //console.log(gamelog);

      if(gamelog.length > 0) {
        //console.log("Starting for loop");
        for(let i = 0; i < gamelog.length; i++) {
          //console.log("New game in log");
          let game = gamelog[i];

          let tr = document.createElement("tr");

          let date = document.createElement("td");

          date.classList.add("compositeCell");

          let statDate = game.statDate.substring(2);

          //console.log(statDate);

          date.textContent = statDate;

          tr.appendChild(date);

          let composite = document.createElement("td");

          composite.classList.add("compositeCell");

          composite.textContent = `${game.mlbName} (${game.compositeNumber})`;

          tr.appendChild(composite);

          //console.log(game);

          if(playerObject.role == 1) {
            let ba = document.createElement("td");

            ba.classList.add("compositeCell");

            let baVal = game.ba;

            if(baVal == null) {
              baVal = ".000"
            }

            ba.textContent = baVal;

            tr.appendChild(ba);

            let rbi = document.createElement("td");

            rbi.classList.add("compositeCell")

            rbi.textContent = game.rbi;

            tr.appendChild(rbi)

            let runs = document.createElement("td");

            runs.classList.add("compositeCell");

            runs.textContent = game.runs;

            tr.appendChild(runs)

            let hr = document.createElement("td");

            hr.classList.add("compositeCell")

            hr.textContent = game.homeRuns;

            tr.appendChild(hr)

            let sb = document.createElement("td");

            sb.classList.add("compositeCell")

            sb.textContent = game.stolenBases;

            tr.appendChild(sb);
          } else {
            let era = document.createElement("td");

            era.classList.add("compositeCell");

            let eraVal = game.era;

            if(eraVal == null) {
              eraVal = "0.00"
            } else {
              eraVal = Number(game.era).toFixed(2)
            }

            era.textContent = eraVal;

            tr.appendChild(era);

            let ip = document.createElement("td");

            ip.classList.add("compositeCell")

            ip.textContent = game.inningsPitched;

            tr.appendChild(ip);

            let saves = document.createElement("td");

            saves.classList.add("compositeCell")

            saves.textContent = game.saves;

            tr.appendChild(saves);

            let so = document.createElement("td");

            so.classList.add("compositeCell")

            so.textContent = game.strikeOuts;

            tr.appendChild(so)

            let wins = document.createElement("td");

            wins.classList.add("compositeCell");

            wins.textContent = game.wins;

            tr.appendChild(wins);
          }
          
          body.appendChild(tr);
        }
      }
    }
    
  }, [showPlayerModal]);

  
  React.useEffect(async () => {
    console.log(`Changed! statID = ${statID}`);
    if(statID != 0) {
        setStatTitle(statNameArr[statID - 1]);
        if(statID > 5) {
            setShowPitcherHeaders(true);
        } else {
            setShowPitcherHeaders(false);
        }
        clearTable("leadertable");
        await fillTable();
    }
    
  }, [statID]);

  React.useEffect(async () => {
    /*let currentID = statID;

    console.log(`Setting to zero`)

    setStatID(0);

    console.log(`Set to zero, currentID = ${currentID}. Setting to currentID`);

    setStatID(currentID);

    console.log(statID);*/
  }, [currentLeague]);

  return (
    <div className="background-image-div">
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">
        <div className="div-content mx-auto mt-5" id="leagueleaders">
          {showLeagueDropdown && (<div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle mb-5" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {currentLeagueName}
            </button>
            <ul className="dropdown-menu" id="leagueMenu">
            </ul>
          </div>)}
          <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle mb-5" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {statTitle}
            </button>
            <ul className="dropdown-menu" id="statMenu">
                <li><a className="dropdown-item" type="button" onClick={async () => {setStatID(1)}}>Batting Average</a></li>
                <li><a className="dropdown-item" type="button" onClick={async () => {setStatID(2)}}>Home Runs</a></li>
                <li><a className="dropdown-item" type="button" onClick={async () => {setStatID(3)}}>Runs</a></li>
                <li><a className="dropdown-item" type="button" onClick={async () => {setStatID(4)}}>RBI</a></li>
                <li><a className="dropdown-item" type="button" onClick={async () => {setStatID(5)}}>Stolen Bases</a></li>

                <li><a className="dropdown-item" type="button" onClick={async () => {setStatID(6)}}>Earned Run Average</a></li>
                <li><a className="dropdown-item" type="button" onClick={async () => {setStatID(7)}}>Wins</a></li>
                <li><a className="dropdown-item" type="button" onClick={async () => {setStatID(8)}}>Innings Pitched</a></li>
                <li><a className="dropdown-item" type="button" onClick={async () => {setStatID(9)}}>Strikeouts</a></li>
                <li><a className="dropdown-item" type="button" onClick={async () => {setStatID(10)}}>Saves</a></li>
            </ul>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            <table className="table table-light" id="htable">
              <thead className="table-secondary">
                <tr className="table-dark"><th colSpan={8}><h2 className="tableHeader">{statTitle} Leaders - {currentLeagueName} League</h2></th></tr>
                {!showPitcherHeaders? 
                    (
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Owner</th>
                            <th>BA</th>
                            <th>HR</th>
                            <th>Runs</th>
                            <th>RBI</th>
                            <th>SB</th>
                        </tr>
                    ) : 
                    (
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Owner</th>
                            <th>ERA</th>
                            <th>Wins</th>
                            <th>Saves</th>
                            <th>IP</th>
                            <th>SO</th>
                        </tr>
                    )
                }
                  
              </thead>
              <tbody id="leadertable">
                
              </tbody>
            </table>
          </div>
        </div>
        <Modal show={showTeamModal} onHide={closeAllModals} contentClassName="text-light bg-light compositeModal" className={""}>
          <Modal.Header className="bg-dark">
            <Modal.Title style={{color:"rgb(0, 163, 41)"}}>{teamObject.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive table-bordered">
              <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                <thead>
                  <tr><th className="" style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"5"}>Total Stats</th></tr>
                    
                    <tr className="table-secondary">
                      <th className="compositeCell">ERA</th>
                      <th className="compositeCell">Innings Pitched</th>
                      <th className="compositeCell">Saves</th>
                      <th className="compositeCell">Strike Outs</th>
                      <th className="compositeCell">Wins</th>
                    </tr>
                    
                </thead>
                <tbody>            
                  <tr>
                    <td className="compositeCell">{teamObject.era}</td>
                    <td className="compositeCell">{teamObject.inningsPitched}</td>
                    <td className="compositeCell">{teamObject.saves}</td>
                    <td className="compositeCell">{teamObject.strikeOuts}</td>
                    <td className="compositeCell">{teamObject.wins}</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
        
            <div className="mt-4 table-responsive table-bordered">
                <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                    <thead>
                      <tr><th style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"7"}>Game Log</th></tr>
                      
                      <tr className="table-secondary">
                          <th className="compositeCell">Date</th>
                          <th className="compositeCell">Composite</th>
                          <th className="compositeCell">ERA</th>
                          <th className="compositeCell">IP</th>
                          <th className="compositeCell">Saves</th>
                          <th className="compositeCell">SO</th>
                          <th className="compositeCell">Wins</th>
                      </tr>
                    </thead>
                    <tbody id="gameLogBody">
                    </tbody>
                </table>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
              <button className="btn btn-danger me-auto" onClick={async () => {await closeAllModals();}}>Close</button>
          </Modal.Footer>
        </Modal>
        <Modal show={showPlayerModal} onHide={closeAllModals} contentClassName="text-light bg-light compositeModal " className={""}>
          <Modal.Header className="bg-dark">
            <Modal.Title style={{color:"rgb(0, 163, 41)"}}>{playerObject.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive table-bordered ">
              <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                <thead>
                  <tr><th className="" style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"7"}>Total Stats</th></tr>
                    {showPitcherStats? (
                      <tr className="table-secondary">
                        <th className="compositeCell">ERA</th>
                        <th className="compositeCell">IP</th>
                        <th className="compositeCell">Saves</th>
                        <th className="compositeCell">SO</th>
                        <th className="compositeCell">Wins</th>
                      </tr>
                    )
                    : (
                      <tr className="table-secondary">
                        <th className="compositeCell">BA</th>
                        <th className="compositeCell">RBI</th>
                        <th className="compositeCell">Runs</th>
                        <th className="compositeCell">HR</th>
                        <th className="compositeCell">SB</th>
                      </tr>
                    )}
                </thead>
                <tbody>
                  {showPitcherStats? (
                    <tr>
                      <td className="compositeCell">{playerObject.era}</td>
                      <td className="compositeCell">{playerObject.inningsPitched}</td>
                      <td className="compositeCell">{playerObject.saves}</td>
                      <td className="compositeCell">{playerObject.strikeOuts}</td>
                      <td className="compositeCell">{playerObject.wins}</td>
                    </tr>
                  )
                  : (
                    <tr>
                      <td className="compositeCell">{playerObject.ba}</td>
                      <td className="compositeCell">{playerObject.rbi}</td>
                      <td className="compositeCell">{playerObject.runs}</td>
                      <td className="compositeCell">{playerObject.homeRuns}</td>
                      <td className="compositeCell">{playerObject.stolenBases}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
            <div className="mt-5 table-responsive table-bordered modalTableOverflow">
              <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                <thead>
                  <tr><th style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"7"}>Game Log</th></tr>
                    {showPitcherStats? (
                      <tr className="table-secondary">
                        <th className="compositeCell">Date</th>
                        <th className="compositeCell">Composite</th>
                        <th className="compositeCell">ERA</th>
                        <th className="compositeCell">IP</th>
                        <th className="compositeCell">Saves</th>
                        <th className="compositeCell">SO</th>
                        <th className="compositeCell">Wins</th>
                      </tr>
                    )
                    : (
                      <tr className="table-secondary">
                        <th className="compositeCell">Date</th>
                        <th className="compositeCell">Composite</th>
                        <th className="compositeCell">BA</th>
                        <th className="compositeCell">RBI</th>
                        <th className="compositeCell">Runs</th>
                        <th className="compositeCell">HR</th>
                        <th className="compositeCell">SB</th>
                      </tr>
                    )}
                </thead>
                <tbody id="gameLogBody">
                </tbody>
              </table>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
              <button className="btn btn-danger me-auto" onClick={async () => {await closeAllModals();}}>Close</button>
          </Modal.Footer>
        </Modal>

        <div className="pagebottom"></div>
      </div>
    </div>
  );
};


export default Leaders;