import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";
//import Moralis from "moralis";
import { useLocation, Navigate } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import Dropzone from "react-dropzone";
import $ from "jquery";
import axios from "axios";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import ModalImage from "react-modal-image"
import TruncateEthAddress from "truncate-eth-address";

const qs = require("qs");
const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;
const formatOrdinals = functions.formatOrdinals;
const clearTable = functions.clearTable;
const scrollToHash = functions.scrollToHash;

const Standings = () => {
  const [showTeamModal,setShowTeamModal] = React.useState(false);

  const [teamObject, setTeamObject] = React.useState({id: 0});

  const [currentLeague, setCurrentLeague] = React.useState(0);

  const [currentLeagueName, setCurrentLeagueName] = React.useState("");

  const [showLeagueDropdown, setShowLeagueDropdown] = React.useState(false);

  const currentUser = useSelector((state) => state.session.currentUser);

  const location = useLocation();

  const dispatch = useDispatch();

  async function closeAllModals() {
    setShowTeamModal(false);
  }

  async function fillTable() {
    if(document.getElementById("standings")) {
      //scrollToHash();
    
      // Grab the team data of the user in question
      const b = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Standings.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=300&ca=${env.REACT_APP_COLLECTIONADD}&lid=${currentLeague}`).then(async (bresult) => {
        const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Standings.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=301&ca=${env.REACT_APP_COLLECTIONADD}&lid=${currentLeague}`).then(async (result) => {
          // Then, if the user has any players, grab them and put them into the table.

          console.log(result);
          
          if(document.getElementById("teamtable") && result.data.length > 0) {
              const table = document.getElementById("teamtable");

              const hitterTable = document.getElementById("hittertable");

              const pitcherTable = document.getElementById("pitchertable");

              let row = 0;

              let tied = [];
              let val = 0;

              for(let i = 0; i < result.data.length; i++) {
                let player = result.data[i];

                if(player.place == val) {
                  tied.push(player.place);
                }
                val = player.place;
              }
              // For each player, create a table row and fill them with.
              for(let i = 0; i < result.data.length; i++) {
                  console.log("new row");
                  // Initialize the current player for brevity's sake.
                  let player = result.data[i];

                  let playerRank = bresult.data[i];
                  row += 1;

                  let address = player.ownerAddress;

                  // Initialize the new row, then-
                  let tr = document.createElement("tr");

                  let htr = document.createElement("tr");

                  let ptr = document.createElement("tr");

                  let playerData = JSON.stringify(player);

                  $(tr).attr("data-team",playerData);

                  // -make sure it has an ID we can refer to it by, then,-
                  tr.id = row.toString();

                  htr.id = `h${row.toString()}`;

                  ptr.id = `p${row.toString()}`;

                  let t = table;

                  let ht = hitterTable;

                  let pt = pitcherTable;

                  // -add it to the new table.
                  t.appendChild(tr);

                  ht.appendChild(htr);

                  pt.appendChild(ptr);

                  let teamImg= document.createElement("td");

                  teamImg.classList.add("imgcont","align-middle")

                  let img = <ModalImage
                          className={`rosterimg`}
                          small={`${player.teamLogo}`}
                          medium={`${player.teamLogo}`}
                      />
                  ReactDOM.render(img,teamImg)

                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                  let playerCard = document.createElement("td");

                  playerCard.setAttribute('style', 'white-space: pre;');

                  playerCard.classList.add("align-middle");

                  let name = player.teamName;

                  if(name == player.ownerAddress) {
                    name = TruncateEthAddress(name);
                  }

                  let playerCardButton = document.createElement("a");

                  playerCardButton.classList.add("btn","px-0","py-0","playerButton");

                  playerCardButton.href = `/#/teamstats/${address}`
  
                  playerCardButton.innerHTML = name;

                  playerCard.appendChild(playerCardButton);

                  // Placeholder stats for now.
                  // TO DO: Actually insert player stats
                  let place = document.createElement("td");

                  place.classList.add("align-middle");

                  let tie = "";

                  if(tied.includes(player.place)) {
                    tie = "<sup><i>T</i></sup>"
                  }

                  place.innerHTML = `${player.place}${tie}`

                  let hp = document.createElement("td");

                  hp.classList.add("align-middle");

                  hp.innerHTML = `<a class="playerButton" href="/#/standings#h${row}">${player.hitterPoints}</a>`;

                  let pp = document.createElement("td");

                  pp.classList.add("align-middle");

                  pp.innerHTML = `<a class="playerButton" href="/#/standings#p${row}">${player.pitcherPoints}</a>`;

                  let total = document.createElement("td");

                  total.classList.add("align-middle");

                  total.style.fontWeight = "bold";

                  total.style.fontSize = "1.05em"

                  total.textContent = `${player.totalPoints}`

                  // Then, now that everything's been initialized, add all the cells to the table row.
                  tr.appendChild(teamImg);

                  tr.appendChild(playerCard);

                  tr.appendChild(place);

                  tr.appendChild(hp);

                  tr.appendChild(pp);

                  tr.appendChild(total);

                  let hTeamImage = document.createElement("td");

                  hTeamImage.classList.add("imgcont","align-middle")

                  let himg = <ModalImage
                          className={`rosterimg`}
                          small={`${player.teamLogo}`}
                          medium={`${player.teamLogo}`}
                      />
                  ReactDOM.render(himg,hTeamImage)

                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                  let hplayerCard = document.createElement("td");

                  hplayerCard.setAttribute('style', 'white-space: pre;');

                  hplayerCard.classList.add("align-middle");

                  let hplayerCardButton = document.createElement("a");

                  hplayerCardButton.classList.add("btn","px-0","py-0","playerButton");

                  hplayerCardButton.href = `/#/teamstats/${address}`
  
                  hplayerCardButton.innerHTML = name;

                  //hplayerCardButton.onclick = redirectToTeamstats.bind(null,address);

                  hplayerCard.appendChild(hplayerCardButton);

                  let ba = document.createElement("td");

                  ba.classList.add("align-middle");

                  ba.innerHTML = `${playerRank.baPts}<sup><i>${formatOrdinals(playerRank.baRank)}</i></sup>`

                  let sb = document.createElement("td");

                  sb.classList.add("align-middle");

                  sb.innerHTML = `${playerRank.sbPts}<sup><i>${formatOrdinals(playerRank.sbRank)}</i></sup>`

                  let rbi = document.createElement("td");

                  rbi.classList.add("align-middle");

                  rbi.innerHTML = `${playerRank.rbiPts}<sup><i>${formatOrdinals(playerRank.rbiRank)}</i></sup>`

                  let runs = document.createElement("td");

                  runs.classList.add("align-middle");

                  runs.innerHTML = `${playerRank.runsPts}<sup><i>${formatOrdinals(playerRank.runsRank)}</i></sup>`

                  let hr = document.createElement("td");

                  hr.classList.add("align-middle");

                  hr.innerHTML = `${playerRank.hrPts}<sup><i>${formatOrdinals(playerRank.hrRank)}</i></sup>`

                  let htotal = document.createElement("td");

                  htotal.classList.add("align-middle");

                  htotal.innerHTML = `${player.hitterPoints}`

                  htr.appendChild(hTeamImage);

                  htr.appendChild(hplayerCard);

                  htr.appendChild(ba);

                  htr.appendChild(sb);

                  htr.appendChild(rbi);

                  htr.appendChild(runs);

                  htr.appendChild(hr);

                  htr.appendChild(htotal);

                  let pTeamImage = document.createElement("td");

                  pTeamImage.classList.add("imgcont","align-middle")

                  let pimg = <ModalImage
                          className={`rosterimg`}
                          small={`${player.teamLogo}`}
                          medium={`${player.teamLogo}`}
                      />
                  ReactDOM.render(pimg,pTeamImage)

                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                  let pplayerCard = document.createElement("td");

                  pplayerCard.setAttribute('style', 'white-space: pre;');

                  pplayerCard.classList.add("align-middle");

                  let pplayerCardButton = document.createElement("a");

                  pplayerCardButton.classList.add("btn","px-0","py-0","playerButton");

                  pplayerCardButton.href = `/#/teamstats/${address}`
  
                  pplayerCardButton.innerHTML = name;

                  pplayerCard.appendChild(pplayerCardButton);

                  let so = document.createElement("td");

                  so.classList.add("align-middle");

                  so.innerHTML = `${playerRank.soPts}<sup><i>${formatOrdinals(playerRank.soRank)}</i></sup>`

                  let ip = document.createElement("td");

                  ip.classList.add("align-middle");

                  ip.innerHTML = `${playerRank.ipPts}<sup><i>${formatOrdinals(playerRank.ipRank)}</i></sup>`

                  let sv = document.createElement("td");

                  sv.classList.add("align-middle");

                  sv.innerHTML = `${playerRank.svPts}<sup><i>${formatOrdinals(playerRank.svRank)}</i></sup>`

                  let era = document.createElement("td");

                  era.classList.add("align-middle");

                  era.innerHTML = `${playerRank.eraPts}<sup><i>${formatOrdinals(playerRank.eraRank)}</i></sup>`

                  let wins = document.createElement("td");

                  wins.classList.add("align-middle");

                  wins.innerHTML = `${playerRank.winsPts}<sup><i>${formatOrdinals(playerRank.winsRank)}</i></sup>`

                  let ptotal = document.createElement("td");

                  ptotal.classList.add("align-middle");

                  ptotal.innerHTML = `${player.pitcherPoints}`

                  ptr.appendChild(pTeamImage);

                  ptr.appendChild(pplayerCard);

                  ptr.appendChild(so);

                  ptr.appendChild(ip);

                  ptr.appendChild(sv);

                  ptr.appendChild(era);

                  ptr.appendChild(wins);

                  ptr.appendChild(ptotal);
              }
          }
      
        })
      })
      
    }
  }

  async function changeLeague(name, id) {
    setCurrentLeague(id);
    setCurrentLeagueName(name);
  }

  React.useEffect(async () => {
    clearTable("teamtable");
    clearTable("pitchertable");
    clearTable("hittertable");
    await fillTable();

    //scrollToHash();
  }, [currentLeague]);

  // Runs on pageload if the user is authenticated. Does a lot of things: Fills in team name, team logo, alongside the team table itself.
  React.useEffect(async () => {
      //console.log('location', location.pathname); // { key: "3uu089" }
      // Fire whatever function you need.

      // If it can find the roster element
      // (this is necessary because if you're just flipping through pages quickly it will rapidly build up errors and freeze the app as it is not finding the element in question)
      
      const q = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Leagues.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=401&ca=${env.REACT_APP_COLLECTIONADD}`).then(async leagueRes => {
        console.log(leagueRes);
        const leagues = document.getElementById("leagueMenu");
        let defaultLeague = 1;
        let defaultLeagueName = leagueRes.data[0].leagueName;
        if(leagueRes.data.length > 1) {
          setShowLeagueDropdown(true);
        } else {
          setCurrentLeague(defaultLeague);
          setCurrentLeagueName(defaultLeagueName);
        }
        
      })
    
  }, []);
  React.useEffect(async () => {
    if(showLeagueDropdown) {
      const q = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Leagues.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=401&ca=${env.REACT_APP_COLLECTIONADD}`).then(async leagueRes => {
        //console.log(leagueRes);
        const leagues = document.getElementById("leagueMenu");
        let defaultLeague = 1;
        let defaultLeagueName = leagueRes.data[0].leagueName;
        if(leagueRes.data.length > 1) {
          for(let i = 0; i < leagueRes.data.length; i++) {
            let league = leagueRes.data[i];
    
            let li = document.createElement("li");
    
            let button = document.createElement("a");
    
            button.classList.add("dropdown-item");
    
            li.appendChild(button);
    
            button.textContent = league.leagueName;
    
            button.onclick = changeLeague.bind(null,league.leagueName,league.leagueID);

            button.type = "button"

            leagues.appendChild(button);
          }
          
          if(currentUser) {
            console.log("logged in")
            const b = fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${currentUser}&c=${env.REACT_APP_COLLECTIONADD}`).then(async userRes => {
              defaultLeague = userRes.data[0].leagueID
            })
          }
        }
        setCurrentLeague(defaultLeague);
        setCurrentLeagueName(defaultLeagueName);
      })
    }
    
    
  }, [showLeagueDropdown]);

  return (
    <div className="background-image-div">
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">
        <div className="div-content mx-auto mt-5" id="standings">
          {showLeagueDropdown && (<div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle mb-5" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {currentLeagueName}
            </button>
            <ul className="dropdown-menu" id="leagueMenu">
            </ul>
          </div>)}
          <div className="table-responsive h-75 w-sm-50 w-100">
            <table className="table table-light">
              <thead className="table-secondary">
                <tr className="table-dark"><th colSpan={6}><h2 className="tableHeader">Overall Standings - {currentLeagueName} League</h2></th></tr>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Place</th>
                    <th>Hitter Pts</th>
                    <th>Pitcher Pts</th>
                    <th>Total Pts</th>
                  </tr>
              </thead>
              <tbody id="teamtable">
                
              </tbody>
            </table>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            <table className="table table-light">
              <thead className="table-secondary">
                <tr className="table-dark"><th colSpan={8}><h2 className="tableHeader">Hitter Ranks - {currentLeagueName} League</h2></th></tr>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>BA Pts</th>
                    <th>SB Pts</th>
                    <th>RBI Pts</th>
                    <th>Runs Pts</th>
                    <th>HR Pts</th>
                    <th>Hitter Pts</th>
                  </tr>
              </thead>
              <tbody id="hittertable">
                
              </tbody>
            </table>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            <table className="table table-light">
              <thead className="table-secondary">
                <tr className="table-dark"><th colSpan={8}><h2 className="tableHeader">Pitcher Ranks - {currentLeagueName} League</h2></th></tr>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>SO Pts</th>
                    <th>IP Pts</th>
                    <th>SV Pts</th>
                    <th>ERA Pts</th>
                    <th>Wins Pts</th>
                    <th>Pitcher Pts</th>
                  </tr>
              </thead>
              <tbody id="pitchertable">
                
              </tbody>
            </table>
          </div>
        </div>
        <Modal show={showTeamModal} onHide={closeAllModals} contentClassName="text-light bg-light compositeModal" className={""}>
          <Modal.Header className="bg-dark">
            <Modal.Title style={{color:"rgb(0, 163, 41)"}}>{teamObject.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive table-bordered">
              <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                <thead>
                  <tr><th className="" style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"5"}>Total Stats</th></tr>
                    
                    <tr className="table-secondary">
                      <th className="compositeCell">ERA</th>
                      <th className="compositeCell">Innings Pitched</th>
                      <th className="compositeCell">Saves</th>
                      <th className="compositeCell">Strike Outs</th>
                      <th className="compositeCell">Wins</th>
                    </tr>
                    
                </thead>
                <tbody>            
                  <tr>
                    <td className="compositeCell">{teamObject.era}</td>
                    <td className="compositeCell">{teamObject.inningsPitched}</td>
                    <td className="compositeCell">{teamObject.saves}</td>
                    <td className="compositeCell">{teamObject.strikeOuts}</td>
                    <td className="compositeCell">{teamObject.wins}</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
        
            <div className="mt-4 table-responsive table-bordered">
                <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                    <thead>
                      <tr><th style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"7"}>Game Log</th></tr>
                      
                      <tr className="table-secondary">
                          <th className="compositeCell">Date</th>
                          <th className="compositeCell">Composite</th>
                          <th className="compositeCell">ERA</th>
                          <th className="compositeCell">IP</th>
                          <th className="compositeCell">Saves</th>
                          <th className="compositeCell">SO</th>
                          <th className="compositeCell">Wins</th>
                      </tr>
                    </thead>
                    <tbody id="gameLogBody">
                    </tbody>
                </table>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
              <button className="btn btn-danger me-auto" onClick={async () => {await closeAllModals();}}>Close</button>
          </Modal.Footer>
        </Modal>

        <div className="pagebottom"></div>
      </div>
    </div>
  );
};


export default Standings;