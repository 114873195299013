import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";
//import Moralis from "moralis"
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import { useLocation } from "react-router-dom";
import axios from "axios";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import OpenApp from "react-open-app";

const qs = require("qs");
const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;
const versionMatched = functions.versionMatched;


const Settings = () => {
    //const { authenticate, isAuthenticated, user, logout } = useMoralis();

    // Initialization of boolean state: Indicates whether or not a file has been uploaded.
    const [fileUploaded, setfileUploaded] = React.useState(false);

    // Initialization of boolean state: The current image within the profile picture editor.
    const [pfpEditorImage, setEditorImage] = React.useState();

    // Initialization of boolean state: This isn't something that's going to be changed within normal functionality of the app.
    const [allowZoomOut, setAllowZoomOut] = React.useState(false);

    // Initialization of boolean state: Whether or not the submit button for the username is allowed to be clicked. It requires one of the radio buttons to be selected, and depending on which one is selected there's different criteria for it to be flipped to true.
    const [allowUsernameSub, allowUSub] = React.useState(false);
    
    // Initialization of float state: The zoom magnification of the editor image. Higher is further out. 1 is the furthest it will go without allowZoomOut being true, and 0 is the closest it will be zoomed in.
    const [zoom, setZoom] = React.useState(1);

    // Initialization of string state: This is the state that displays the current username in the username options section.
    const [username, setUsername] = React.useState();

    // Initialization of string state: This is the state that displays the current username in the username options section.
    const [address, setAddress] = React.useState();

    // Initialization of string state: This is the state that holds the default value of the input box for the username. Right now it's blank and nothing changes it.
    const [customName, setCustomName] = React.useState();

    // Initialization of a string state: This is the state that holds the value of the .eth name. If the SQL service is unable to find one associated with the address of the current account, it will remain this way and be unable to be selected.
    const [dotEth, setDotEth] = React.useState("None Detected");

    // Initialization of a string state. If the SQL service finds a .eth avatar associated with the address, it will change the string to a link that links to the image.
    const [dotEthpfp, setDotEthpfp] = React.useState("None Detected");

    // Initialization of a boolean state. At the same time as when the dotEthpfp state changes, this is also flipped to true.
    const [dotEthpfpdet, setDotEthpfpdet] = React.useState(false);

    // Initialization of a string state. Whenever the custom username input box encounters a blank box or a username that's already been used, it turns into a warning message.
    const [customFeedback, setCustomFeedback] = React.useState();

    const [showDiscordLink,setShowDiscordLink] = React.useState(false);

    const [showModal, setShowModal] = React.useState(false);

    const currentUser = useSelector((state) => state.session.currentUser)

    let editor = React.createRef();

    const location = useLocation();

    const dispatch = useDispatch();

    async function logout() {
        dispatch(setSession(null));
        
        dispatch(disconnect());
        const logoutQuery = `${env.REACT_APP_BASEURLSERVICE}/auth.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}`

        const postLogoutObj = {
        type:5,
        
        sid: currentUser
        };

        await axios.post(logoutQuery, qs.stringify(postLogoutObj))
    }

    // Runs upon the page loading. It sets the username, dotEth, dotEthpfp, and dotEthpfp states.
    React.useEffect(async () => {
        if(currentUser) {
            //console.log('location', location.pathname); // { key: "3uu089" }
            // Fire whatever function you need.
    
            // First, initialize the variables the SQL service needs and feed it into the link.
            const id = currentUser;

            const typeoneadd = `${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${id}&c=${env.REACT_APP_COLLECTIONADD}`

            console.log(typeoneadd)

            // Then send the link through a GET request
            const c = await fetchAsync(typeoneadd).then(async (unameRes) => {
                let auth = authenticated(unameRes);
                if(!auth) {
                    logout();

                    toast.error("Unauthenticated session. Please log in again.");
                }
                else {
                    const did = unameRes.data[0].discordID;

                    if(did == 0) {
                        setShowDiscordLink(true);
                    }
    
                    // Initialize the username property into a variable, then see if you're still on the same page by checking if the username-content element can be found.
                    const uname = unameRes.data[0].userName;
                
                    if(document.getElementById("username-content")) {
    
                        // First, set the username state
                        setUsername(uname);
    
                        // Initialize the variables you need for the type 67 SQL service call.
                        const add = unameRes.data[0].user;
    
                        setAddress(add);
    
                        const dotethLink = `${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=67&u=${add}&c=${env.REACT_APP_COLLECTIONADD}`;
    
                        //console.log(dotethLink)
    
                        // Send the link as a GET request. This is so we can check if there's a .eth username available for this account.
                        const d = await fetch(dotethLink).then(res => res.json()).then((dotethres) => {
                            console.log(dotethres);
    
                            const results = dotethres.results;
                            // ERROR66 and ERROR67 are basically the same error: No .eth username.
                            if(!results.ERROR66 && !results.ERROR67) {
    
                                // If you've got a .eth username, set the doteth state to what's returned.
                                const name = dotethres.data[0].ethName;
    
                                setDotEth(name);
    
                                // You can have a .eth username while not having a .eth avatar!
                                // Check if there's one, and if there is, set the dotEthpfpdet state to true and the dotEthpfp state to the link the SQL service provides.
                                if(dotethres.data[0].ethAvatar != null) {
                                    setDotEthpfpdet(true);
    
                                    setDotEthpfp(dotethres.data[0].ethAvatar)
                                }
                            }
                        })
                    }
                }
                
            })
        }
    }, [currentUser]);

    
    async function handleShow() {
        setShowModal(true);
    }
    async function handleClose() {
        setShowModal(false);
    }

    async function fetchAsync(query) {
        let response = await fetch(query);
    
        let data = await response.json();
    
        return data;
    }

    // This is the function that submits a profile picture to the SQL service.
    async function sub(){

        // First case, we're submitting an uploaded-to-the-editor image. Non-.eth, in other words.
        if(document.querySelector('input[name="pfpradio"]:checked') == undefined){
            const formdata = new FormData();

            const photo = await editor.current.getImageScaledToCanvas().toDataURL();

            const id = currentUser

            let imageURL;

            await fetch(photo).then(async (res) => (imageURL = await res.blob()))

            //const name = "pfp.jpg"

            const image = imageURL;

            formdata.append("image",image);

            const uploadQuery = `${env.REACT_APP_IMAGEUPLOAD}/img`;

            const uploadObj = {
                session: id,

                which: "pfp"
            }

            formdata.append("info", qs.stringify(uploadObj));

            const a = await axios.post(uploadQuery,formdata, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(async (uploadRes) => {
                console.log(uploadRes.data);

                if(uploadRes.data.result == "success") {
                    document.getElementById("pfp").src = uploadRes.data.link + "?random="+new Date().getTime();

                    toast.success("Profile picture has been updated!")
                }
            })

            // TO DO: Replace this stuff with something that sends off the blob to whatever ends up uploading that and setting the pfp
            /*await moralisFile.save().then(async (res) => {
                user.set("pfp", moralisFile);

                await user.save().then(async (response) => {
                    const imgUrl = await user.get("pfp").url();

                    document.getElementById("pfp").src = imgUrl;

                    const c = await fetch(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=10&u=${id}&up=${imgUrl}&c=${env.REACT_APP_COLLECTIONADD}`).then(res => res.json()).then((updateRes) => {
                        console.log(updateRes);

                        setfileUploaded(false);

                        setEditorImage(null);
                    })
                });
            })*/
        }
        else { // Now we're submitting a .eth profile picture.

            // Just need to grab the session token here. The dotethpfp state is the image link we're using here, so we don't need anything else.
            console.log("here");
            const id = currentUser

            // Send the fetch, then set the pfp image link to the dotethpfp state.
            const c = await fetch(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=10&u=${id}&up=${dotEthpfp}&c=${env.REACT_APP_COLLECTIONADD}`).then(res => res.json()).then(async (res) => {
                document.getElementById("pfp").src = dotEthpfp;

                console.log(res);
                /*
                let imageURL;

                await fetch(dotEthpfp)
                .then(async (res) => (imageURL = await res.blob()))*/

                // TO DO: Replace this stuff with something that sends off the blob of the doteth image to whatever ends up uploading that and setting pfp
                /*
                const moralisFile = new Moralis.File("pfp.jpg",imageURL);

                await moralisFile.save().then(async (res) => {
                    user.set("pfp", moralisFile);
    
                    await user.save().then(async (response) => {
                        const imgUrl = await user.get("pfp").url();
                    });
                })

                user.set("pfp",);
            
                console.log(res);

                document.getElementById("usingDotethpfp").checked = false;*/
            })
        }
    }

    if (!currentUser) {
        return (
            <div id="settop" className="background-image-div" style={{backgroundImage:"url(/images/bg.jpeg)"}}>
                
            </div>
        );
    }
    
    // This function handles whatever's uploaded to the team logo image dropzone.
    async function startCropping(file) {
        // If there's an actual file that's been uploaded, start editing it.
        if(file.length >= 1) {
            console.log("Cropping");
            if(!fileUploaded) {
              setfileUploaded(true);
            }
            const image = file[0];
            setEditorImage(image);
        }
    }

    // This function is what's called whenever the zoombar in the image editor is used. It grabs the value of the bar, then translates it to something that the zoom variable accepts into the editor..
    function handleScale() {
        const scale = document.getElementById("scale").value;
        setZoom(parseFloat(scale));
    }

    // This is a test function. Remove it later.
    function findChecked() {
        return false;
    }

    // This function runs whenever one of the radio buttons for the username is selected.
    async function onRadioChange() {
        // Basically, the logic goes like this: If you're not already allowing username submissions, once you select a radio button, and it's not the custom username input, start allowing submissions. If it's the custom username input, it has to not be blank before allowing a submission.
        if(!allowUsernameSub) {
            if(document.querySelector('input[name="using"]:checked').id != "usingCustom") {
                allowUSub(true);
            }
            else {
                if(document.getElementById("customInput").classList.contains("val")) {
                    allowUSub(true);
                }
            }
        }
    }

    // This function runs whenever the input box for the username is changed.
    async function onInputChange() {

        // First, it disables submissions. It'll reactivate it later if it meets certain criteria.
        allowUSub(false);

        const u = document.getElementById("customInput").value;

        // It then grabs the current value that's currently in the custom username input box, and sends it to a type 99 SQL request.
        const e = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=99&un=${u}&c=${env.REACT_APP_COLLECTIONADD}`).then(res => {
            console.log(res);

            // First, it wipes the custom input box of any additional classnames.
            document.getElementById("customInput").className = document.getElementById("customInput").className.replace(" err", "");
            document.getElementById("customInput").className = document.getElementById("customInput").className.replace(" val", "");

            // If rows is 0, that means the current input is valid. Sets the custom feedback to blank and then allows username submissions if the custom username radio button is selected.
            if(res.results.rows == 0) {

                // Adds an additional classname that changes the coloration of the border of the textbox to a green color.
                document.getElementById("customInput").className = document.getElementById("customInput").className + " val";

                setCustomFeedback("");

                if(document.querySelector('input[name="using"]:checked')!= undefined) {
                    allowUSub(true);
                }
            }
            else { // Otherwise, set the custom feedback to an error message. Since username submissions are disabled at the beginning of the function, there's no need to disable them now.
                
                // Adds an additional classname that changes the coloration of the border of the textbox to a red color.
                document.getElementById("customInput").className = document.getElementById("customInput").className + " err";
                
                setCustomFeedback("Username can't be blank or a username that's already been registered")
            }
        })
    }

    async function subUsername() {
        const element = document.querySelector('input[name="using"]:checked');

        const elementID = element.id;

        const id = currentUser;

        let val;

        if(elementID == "usingAddress" || elementID == "usingDoteth") {
            val =  element.value;
        }
        if(elementID == "usingCustom") {
            val = document.getElementById("customInput").value;
        }

        const c = await fetch(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=10&u=${id}&un=${val}&c=${env.REACT_APP_COLLECTIONADD}`).then(res => res.json()).then(async (res) => {
            console.log(res);

            setUsername(res.data[0].userName);

            toast.success("Username has been changed!");
        })
    }

    return (
        <div id="settop" className="background-image-div">
            <div className="bg-div">
                <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                    <source src="/images/bg_final.mp4" type="video/mp4" />
                </video>
            </div>
            <div className="align-self-center div-background-content pt-5">
                <div className="div-content mx-auto bg-home p-lg-5 p-3">
                {showDiscordLink ? (
                    <div className="div-content py-5 mx-auto mt-5 bg-primary text-center">
                        <h2 className="ms-3 mb-2 header-y">Link Discord Account</h2>
                        <br/>
                        <p className="ms-3">If you haven't already done so, link your Discord account to your Metamask address so we can more easily get in touch with you!</p>
                        <div className="button-div" style={{width:"100%"}}>
                            <button className="btn btn-primary" onClick={async () => {await handleShow();}}>Link to Discord</button>
                        </div>
                    </div>
                )
                : (
                    <div className="div-content py-5 mx-auto mt-5 bg-primary text-center">
                        <h2 className="ms-3 mb-2 header-y">✅ Discord Linked!</h2>
                    </div>
                )
                }

                

                <hr className="hr" id="settings-content" />

                
                <div className="div-content mt-5 mx-auto w-75">
                    <form id="pfp-form" className="div-form mt-3">
                        <h2>Profile Picture: </h2>
                        <br/>
                        <Dropzone accept="image/png, image/jpg, image/jpeg" maxFiles={1} maxSize={2097152} onDrop={acceptedFiles => {console.log(acceptedFiles); startCropping(acceptedFiles)}}>
                            {({getRootProps, getInputProps}) => (
                                <section className="dropzone">
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <p className="p-3">Either click this background area or drag an image onto it.</p>
                                </div>
                                </section>
                            )}
                        </Dropzone>
                        <input className="form-check-input" name="pfpradio" id="usingDotethpfp" disabled={!dotEthpfpdet} type={"checkbox"} onChange={async () => setfileUploaded(true)} /><label htmlFor="usingDotethpfp" className="ms-3 form-check-label">.eth Profile Picture: {dotEthpfp}</label>
                        <button className="form-button btn btn-primary" type="button" id="pfp-sub" disabled={!fileUploaded} onClick={async () => { await sub() }}>Submit</button>
                    </form>
                    {(fileUploaded && pfpEditorImage) && (
                        <div id="editor" className="editor mt-5">
                            <AvatarEditor ref={editor}
                                image={pfpEditorImage}
                                scale={zoom}
                                width={250}
                                height={250}
                                border={10}
                                borderRadius={
                                    250 / (100 / 50)
                                }
                                color={[1, 1, 1, 0.6]} // RGBA
                                rotate={0}
                            />
                            <br/>
                            Zoom:
                            <input
                                id="scale"
                                name="scale"
                                type="range"
                                onChange={() => {handleScale()}}
                                min={allowZoomOut ? '0.1' : '1'}
                                max="2"
                                step="0.01"
                                defaultValue="1"
                            />
                        </div>
                    )}
                    
                    
                </div>
                <hr className="hr" id="username-content" />
                <div  className="div-content mt-5 mx-auto w-75 pb-5">
                    <form id="username-form" className="div-form mt-3">
                        <h2 className="text-break">Username: {username}</h2>
                        <br/>
                        <div className="form-check">
                            <input className="form-check-input" name="using" id="usingAddress" value={address} type={"radio"} onChange={async () => await onRadioChange()} /><label htmlFor="usingAddress" className="ms-3 form-check-label text-break">Wallet Address: {address}</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" name="using" id="usingCustom" type={"radio"} onChange={async () => await onRadioChange()}/><label htmlFor="usingCustom" className="ms-3 form-check-label">Custom Username: <input type={"text"} className="form-control" id="customInput" onChange={async () => { await onInputChange(); }}  defaultValue={customName}autoComplete={"off"}></input> <p className="errfeedback">{customFeedback}</p> </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" name="using" id="usingDoteth" value={dotEth} disabled={(dotEth == "None Detected")} type={"radio"} onChange={async () => await onRadioChange()}/><label htmlFor="usingDotethpfp" className="ms-3 form-check-label text-break">.eth Username: {dotEth}</label>
                        </div>
                        <button className="form-button mt-3 btn btn-primary" type="button" id="user-sub" disabled={(!allowUsernameSub)} onClick={async () => { await subUsername() }}>Save Username</button>
                    </form>
                </div>
                </div>
                

                
            </div>

            <Modal show={showModal} contentClassName="text-light bg-dark" className={"mt-5 "}>
                <Modal.Header>
                    <Modal.Title>Link Discord</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to link your discord? This will log you out!
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary me-auto" onClick={async () => {await handleClose();}}>No</button>

                    <a className="btn btn-primary" href={`https://eb-oauth.ethball.io/link/` + currentUser}>Yes</a>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Settings;