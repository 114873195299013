import React from "react";
import { useNavigate } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link"
import ReactDOM from "react-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connectweb3, disconnect as disconnectweb3 } from "../redux/blockchain/blockchainActions";
import { setSession } from "../redux/session/sessionSlice"
import { setAddress } from "../redux/session/addressSlice"
import { fetchData } from "../redux/data/dataActions";
//import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
//import Moralis from "moralis";
import detectEthereumProvider from "@metamask/detect-provider";
import {isMobile, deviceDetect} from "react-device-detect";
import Web3 from "web3";
import axios from "axios";
import * as functions from "./functions.js";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {
  initWeb3Onboard,
  //goerliGasBlockPrices,
  zmokRPC
} from './onboard.js'
import {
  useAccountCenter,
  useConnectWallet,
  useWallets
} from '@web3-onboard/react'
import { Signer } from "ethers";
import "bootstrap/dist/css/bootstrap.min.css";
import {Collapse} from "bootstrap/dist/js/bootstrap.bundle.min";
import $ from "jquery";
import Popper from "popper.js";

//import { Modal } from "react-bootstrap";

const qs = require("qs");
const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;
const versionMatched = functions.versionMatched;

function Navigation() {
  // const { authenticate, isAuthenticated, user, logout, enableWeb3, authError } = useMoralis();

  // Security measures for the page. It automatically logs you out of Moralis upon changing accounts or if you're not logged in on the current Ethereum provider

  /*
  Moralis.onAccountChanged((acc) => {
    logout();
  })

  Moralis.onConnect(async () => {
    let web3 = await detectEthereumProvider().then(async (res) => {
      let acc = await res.selectedAddress;

      if(!acc) {
        logout();
      }
    })
  })*/
  
  const location = useLocation();

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet()

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);

  const currentUser = useSelector((state) => state.session.currentUser)

  const address = useSelector((state) => state.address.currentAddress)

  const [web3Onboard, setWeb3Onboard] = React.useState(null)

  const [showLeague, setShowLeague] = React.useState(false);

  const navigate = useNavigate();

  async function walletUpdate(session) {

    const walletlink = `${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?type=3&u=${session}&c=${env.REACT_APP_COLLECTIONADD}&apiKey=${env.REACT_APP_SERVICEAPIKEY}`

    const c = await fetch(walletlink).then(res => res.json()).then(async (unameRes) => {
      const res = unameRes;
      
      console.log(res)
    }).catch((err) => {
      console.log("Error updating wallet: " + err);
    });
  }


  React.useEffect(() => {
    setWeb3Onboard(initWeb3Onboard)
    
  }, [])

  React.useEffect(() => {
    //console.log(location);

    const menuToggle = document.getElementById("nbar");

    const toggleButton = document.getElementById("toggler")

    const bsCollapse = new Collapse(menuToggle,{toggle:false});

    bsCollapse.hide();

    const path = location.pathname;

    let highlight;

    let wipe = document.getElementsByClassName("highlight");

    for(let i = 0; i < wipe.length; i++) {
      let element = wipe[i];

      element.classList.remove("highlight")
    }

    switch(path) {
      case "/mint":
        highlight = "mintlink";
        break;
      case "/terms":
      case "/start":
      case "/beta":
      case "/about":
        highlight = "aboutlink";
        break;
      case "/standings":
      case "/schedule":
        highlight = "standingslink";
        break;
      case "/traits":
      case "/composites":
      case "/leaders":
      case "/leaguestats":
        highlight = "playerlink";
        break;
      case "/team":
      case "/teamstats":
      case "/roster":
      case "/league":
        highlight = "teamlink";
        break;
    }

    if(highlight) {
      console.log("Highlight something");

      highlight = document.getElementById(highlight);

      if(highlight) {
        highlight.classList.add("highlight")
      }
      
    }
    async function update() {
      const id = currentUser
      const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${id}&c=${env.REACT_APP_COLLECTIONADD}`).then((userRes) => {
        if(userRes.results.ERROR) {
          throw "";
        } else {
          if(userRes.data[0].leagueID != 0) {
            setShowLeague(false);
          } else {
            setShowLeague(true);
          }
        }
      }).catch(err => {
        logout();
  
        toast.error("Encountered an error. Please log in again.");
      }) 
    }
    if(currentUser) {
      update();
    }
    async function check() {
      let match = await versionMatched(env);

      if(!match) {
        window.location.reload();
      }
      functions.scrollToHash();
    }
    check();
  }, [location])

  React.useEffect(() => {
    if(currentUser) {
      async function gaming() {
        //console.log('location', location.pathname); // { key: "3uu089" }
        // Fire whatever function you need.

        // First, initialize the variables the SQL service needs and feed it into the link.
        const id = currentUser;

        const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${id}&c=${env.REACT_APP_COLLECTIONADD}`).then((userRes) => {
          if(userRes.results.ERROR) {
            throw "";
          } else {
            let auth = authenticated(userRes);
            if(!auth) {
              logout();
  
              toast.error("Unauthenticated session. Please log in again.");
            }
            else {
              if(document.getElementById("pfp")) {
                console.log(userRes);
    
                const userPhoto = userRes.data[0].userPfp + "?random="+new Date().getTime();;
      
                document.getElementById("pfp").src = userPhoto;
              }
              if(userRes.data[0].leagueID == 0) {
                setShowLeague(true);
              }
            }
          }
          
          
        }).catch(err => {
          logout();

          toast.error("Encountered an error. Please log in again.");
        }) 
      }
      gaming();
    }
  }, [currentUser]);

  /*React.useEffect(() => {
    console.log(window.ethereum);
  },[wallet])*/

  //const [wallet, setWallet] = React.useState(null);

  async function login() {
      await connect().then(async newwallet => {

        console.log(newwallet);
        //await window.ethereum.request({method: "eth_requestAccounts"});

        //window.web3 = new Web3(window.ethereum);

        //let accounts = await window.web3.eth.getAccounts();

        let account = newwallet[0].accounts[0].address;

        let provider = newwallet[0].provider;

        //setWallet(account);
        const messageObj = {
          type:1,
          eth: account
        }
        //console.log(messageObj);
  
        const messageQuery = `${env.REACT_APP_BASEURLSERVICE}/auth.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}`
  
        //console.log(messageQuery);
        
        const a = await axios.post(messageQuery,qs.stringify(messageObj)).then(async (messageRes) => {
          //console.log(messageRes)
          const message = messageRes.data.data.signedMessage;

          const signature = await provider.request({method: "personal_sign", params: [message,account]})
  
          //const signature = await window.web3.eth.personal.sign(message,account);
  
          const sessionObj = {
            type:2,
            msg: message,
            eth: account,
            sig: signature
          }
  
          //console.log(sessionObj);
  
          const sessionQuery = `${env.REACT_APP_BASEURLSERVICE}/auth.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}`;
          
          const b = await axios.post(sessionQuery,qs.stringify(sessionObj)).then(async (sessionRes) => {
            //console.log(sessionRes.data);
            if(sessionRes.data.results == "SUCCESS") {
              dispatch(setAddress(account));
              dispatch(setSession(sessionRes.data.session));
              dispatch(connectweb3(provider));
  
              //await walletUpdate(sessionRes.data.session);
            }
          })
        })
      }).catch(err => {
        console.log(err);
      });
  }



  async function logout() {
    await disconnect(wallet);

    dispatch(setAddress(null));

    dispatch(setSession(null));

    dispatch(disconnectweb3());

    const logoutQuery = `${env.REACT_APP_BASEURLSERVICE}/auth.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}`

    const postLogoutObj = {
      type:5,
      
      sid: currentUser
    };

    await axios.post(logoutQuery, qs.stringify(postLogoutObj))
  }

  if(!currentUser) {
    return (
      <div className="navigation">
        <nav className="navbar navbar-expand-lg nbar navbar-dark bg-dark justify-content-center">
          <div className="container">
            <div className="empty" style={{height:"54px", width:"40px"}}></div>

            <Link className="navbar-brand align-self-center" smooth to="/#pagetop">
              <img height={"40px"} src="/images/logo.png"/>
            </Link>

            <button className="navbar-toggler" type="button" 
                    data-bs-toggle="collapse"
                    data-bs-target="#nbar"
                    aria-controls="nbar"
                    aria-expanded="false" 
                    aria-label="Toggle navigation"
                    id="toggler">
                <span className="navbar-toggler-icon"></span>
            </button>
          
            <div className=" collapse navbar-collapse" style={{width:"60%"}} id={"nbar"}>
              <div className="mx-auto">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" id="mintlink" smooth to="/mint#pagetop">
                      Buy
                    </Link>
                  </li>
                  <li>
                    <div className="dropdown">
                      <a className="nav-link" id="aboutlink" href="/about" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        About
                      </a>
                      <div className="dropdown-menu bg-dark" role="menu" aria-labelledby="menu1">
                        <Link className="dropdown-item nlinktext" smooth to="/about#pagetop">Overview</Link>
                        <Link className="dropdown-item nlinktext" smooth to="/terms#pagetop">Terms and Conditions</Link>
                        <Link className="dropdown-item nlinktext" smooth to="/beta#pagetop">Get Started</Link>
                        <Link className="dropdown-item nlinktext" smooth to="/beta#pagetop">Beta Testers</Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="dropdown">
                      <a className="nav-link" id="playerlink" href="/leaderboard" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Stats
                      </a>
                      <div className="dropdown-menu bg-dark" role="menu" aria-labelledby="menu1">
                        <Link className="dropdown-item nlinktext" smooth to="/leaguestats#pagetop">Team Totals</Link>
                        <Link className="dropdown-item nlinktext" smooth to="/leaders#pagetop">Leaders</Link>
                        <Link className="dropdown-item nlinktext" smooth to="/traits#pagetop">Traits</Link>
                        <Link className="dropdown-item nlinktext" smooth to="/composites">Composites</Link>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="dropdown">
                      <a className="nav-link" id="standingslink" href="/standings" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Standings
                      </a>
                      <div className="dropdown-menu bg-dark" role="menu" aria-labelledby="menu1">
                        <Link className="dropdown-item nlinktext" smooth to="/standings#pagetop">Team Standings</Link>
                        <Link className="dropdown-item nlinktext" smooth to="/schedule#pagetop">Schedule</Link>
                      </div>
                    </div>
                  </li>
                </ul> 
              </div>
              <div>
                <ul className="navbar-nav">
                  
                  <li className="nav-item mb-2 mt-2">
                    <button className="btn me-3 px-0 py-0" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" onClick={async () => { await login() }}>
                      <span className="material-symbols-outlined md-36 md-light" style={{verticalAlign:"middle"}}>
                        account_balance_wallet
                      </span>
                    </button>
                    <a className="me-3" href="https://discord.gg/rusc2JzmEh" target={"_blank"}>
                      <img src="https://cdn.logojoy.com/wp-content/uploads/20210422095037/discord-mascot.png" height={40} width={40} style={{borderRadius:"50%"}} />
                    </a>
                    <a className="me-3" href="https://twitter.com/ethballio" target={"_blank"}>
                      <img src="https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/twitter_circle-512.png" height={40} width={40} style={{borderRadius:"50%"}} />
                    </a>
                    <a href="https://testnets.opensea.io/collection/ethball-beta" target={"_blank"}>
                      <img src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-White.png" height={40} width={40} style={{borderRadius:"50%"}} />
                    </a>
                  </li>
                </ul>
              </div>
            
            </div>
          </div>
        </nav>
        <ToastContainer />
      </div>
      
    );
  }

  
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand-lg nbar navbar-dark bg-dark justify-content-center">
        <div className="container">
          <div className="empty" style={{height:"54px", width:"40px"}}></div>

          <Link className="navbar-brand align-self-center" smooth to="/#pagetop">
            <img height={"40px"} src="/images/logo.png"/>
          </Link>

          <button className="navbar-toggler" type="button" 
                  data-bs-toggle="collapse"
                  data-bs-target="#nbar"
                  aria-controls="nbar"
                  aria-expanded="false" 
                  aria-label="Toggle navigation"
                  id="toggler">
              <span className="navbar-toggler-icon"></span>
          </button>
        
          <div className=" collapse navbar-collapse" style={{width:"60%"}} id={"nbar"}>
            <div className="mx-auto">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" id="mintlink" smooth to="/mint#pagetop">
                    Buy
                  </Link>
                </li>
                <li>
                    <div className="dropdown">
                      <a className="nav-link" id="aboutlink" href="/about" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        About
                      </a>
                      <div className="dropdown-menu bg-dark" role="menu" aria-labelledby="menu1">
                        <Link className="dropdown-item nlinktext" smooth to="/about#pagetop">Overview</Link>
                        <Link className="dropdown-item nlinktext" smooth to="/terms#pagetop">Terms and Conditions</Link>
                        <Link className="dropdown-item nlinktext" smooth to="/beta#pagetop">Get Started</Link>
                        <Link className="dropdown-item nlinktext" smooth to="/beta#pagetop">Beta Testers</Link>
                      </div>
                    </div>
                </li>
                <li>
                  <div className="dropdown">
                    <a className="nav-link" id="playerlink" href="/leaderboard" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Stats
                    </a>
                    <div className="dropdown-menu bg-dark" role="menu" aria-labelledby="menu1">
                      <Link className="dropdown-item nlinktext" smooth to="/leaguestats#pagetop">Team Totals</Link>
                      <Link className="dropdown-item nlinktext" smooth to="/leaders#pagetop">Leaders</Link>
                      <Link className="dropdown-item nlinktext" smooth to="/traits#pagetop">Traits</Link>
                      <Link className="dropdown-item nlinktext" smooth to="/composites">Composites</Link>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="dropdown">
                    <a className="nav-link" id="standingslink" href="/standings" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Standings
                    </a>
                    <div className="dropdown-menu bg-dark" role="menu" aria-labelledby="menu1">
                      <Link className="dropdown-item nlinktext" smooth to="/standings#pagetop">Team Standings</Link>
                      <Link className="dropdown-item nlinktext" smooth to="/schedule#pagetop">Schedule</Link>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="dropdown">
                    <a className="nav-link" id="teamlink" href="/team" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Manage Team
                    </a>
                    <div className="dropdown-menu bg-dark" role="menu" aria-labelledby="menu2">
                      <Link className="dropdown-item nlinktext" smooth to="/team#tn">Team Name</Link>
                      <Link className="dropdown-item nlinktext" smooth to="/team#tl">Team Logo</Link>
                      <Link className="dropdown-item nlinktext" smooth to="/roster">Manage Roster</Link>
                      <Link className="dropdown-item nlinktext" smooth to={`/teamstats/${address}`}>Team Stats</Link>
                      {showLeague && (
                        <Link className="dropdown-item nlinktext" smooth to="/league">Join a League</Link>
                      )}
                    </div>
                  </div>
                </li>
              </ul> 
            </div>
            <div>
              <ul className="navbar-nav">
                <li className="nav-item mt-2 me-3">
                  <div className="dropdown pfp-dropdown mx-0 px-0">
                    <a href="#" className="" type="button" id="menu3" data-bs-toggle="dropdown">
                      <img className="pfp" id="pfp" src="https://storage.googleapis.com/ethballimages/default-user.png" height={40} width={40}/>
                      <span className="caret"></span>
                    </a>
                    <div className="dropdown-menu bg-dark" role="menu" aria-labelledby="menu3">
                      <Link className="dropdown-item nlinktext" smooth to="/settings#settop">Settings</Link>
                      <a className="dropdown-item nlinktext" href="#" onClick={() => logout()}>Logout</a>
                    </div>
                  </div>
                </li>
                <li className="nav-item mb-2 mt-2">
                  
                  <a className="me-3" href="https://discord.gg/rusc2JzmEh" target={"_blank"}>
                    <img src="https://cdn.logojoy.com/wp-content/uploads/20210422095037/discord-mascot.png" height={40} width={40} style={{borderRadius:"50%"}} />
                  </a>
                  <a className="me-3" href="https://twitter.com/ethballio" target={"_blank"}>
                    <img src="https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/twitter_circle-512.png" height={40} width={40} style={{borderRadius:"50%"}} />
                  </a>
                  <a href="https://testnets.opensea.io/collection/ethball-beta" target={"_blank"}>
                    <img src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-White.png" height={40} width={40} style={{borderRadius:"50%"}} />
                  </a>
                </li>
              </ul>
            </div>
          
          </div>
        </div>
      </nav>
      <ToastContainer />
    </div>
  );
  
}

export default Navigation;