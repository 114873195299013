import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";
//import Moralis from "moralis";
import { useLocation, Navigate, useParams } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import Dropzone from "react-dropzone";
import $ from "jquery";
import axios from "axios";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import ModalImage from "react-modal-image"
import truncateEthAddress from "truncate-eth-address";

const qs = require("qs");
const env = process.env;

const fetchAsync = functions.fetchAsync;
const clearTable = functions.clearTable;

const TeamStats = () => {

  // Moralis functions and properties.
  //const { authenticate, isAuthenticated, user, logout,  } = useMoralis();

  const [teamName, setTeamName] = React.useState("");

  const [redirect,setRedirect] = React.useState(false);

  const [showCompositeModal,setShowCompositeModal] = React.useState(false);

  const [compositeObject, setCompositeObject] = React.useState({id:0});

  const [showPlayerModal,setShowPlayerModal] = React.useState(false);

  const [showPitcherStats, setShowPitcherStats] = React.useState(false);

  const [playerObject, setPlayerObject] = React.useState({id: 0});

  const {address} = useParams();

  const location = useLocation();

  const dispatch = useDispatch();
  

  async function openCompositeModal(id) {
    console.log(id);

    await closeAllModals();

    const newObject = {
      id: id
    };

    setCompositeObject(newObject);

    setShowCompositeModal(true);
  }

  async function openPlayerModal(id, owner,role, playerName) {
    //console.log(id);

    await closeAllModals();

    const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Players.php?apiKey=${env.REACT_APP_PLAYERAPIKEY}&type=2&ei=${id}&oa=${owner}&ca=${env.REACT_APP_COLLECTIONADD}&hp=${role}`).then(async results => {
      const player = results.data;

      const totals = player.totals.data[0];

      //console.log(results);

      const newObject = {
        id: id,
        name: playerName,
        role: role,

        ba: ".000",
        homeRuns: 0,
        rbi: 0,
        runs: 0,
        stolenBases: 0,

        era: "0.00",
        inningsPitched: 0,
        strikeOuts: 0,
        saves: 0,
        wins: 0,

        gameLog: []
      };

      if(player.totals.data.length > 0) {
        if(role == 1) { // Hitter
          newObject.ba = totals.ba;
  
          if(newObject.ba == null) {
            newObject.ba = ".000";
          }
  
          newObject.homeRuns = totals.homeRuns;
  
          newObject.rbi = totals.rbi;
  
          newObject.runs = totals.runs;
  
          newObject.stolenBases = totals.stolenBases;
  
          setShowPitcherStats(false);
        } else { // Pitcher
          newObject.era = Number(totals.era).toFixed(2);
  
          if(totals.era == null) {
            newObject.era = ".00";
          }
  
          newObject.inningsPitched = totals.inningsPitched;
  
          newObject.saves = totals.saves;
  
          newObject.strikeOuts = totals.strikeOuts;
  
          newObject.wins = totals.wins;
  
          setShowPitcherStats(true);
        }
      }
      

      newObject.gameLog = player.gameLog.data
  
      setPlayerObject(newObject);
  
      setShowPlayerModal(true);
    }).catch(err => {
      console.log(err);
    })
    
  }

  async function closeAllModals() {
    setShowCompositeModal(false);

    setShowPlayerModal(false);
  }

  async function fillTable() {
    if(document.getElementById("teamstats")) {
      const b = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Players.php?apiKey=d2d79e1b-1e6d-4c34-966e-f3436c0edaa5&type=3&oa=${address}&ca=${env.REACT_APP_COLLECTIONADD}`).then(async playersRes => {
        console.log(playersRes);
        // Then, if the user has any players, grab them and put them into the table.
        if(document.getElementById("teamtable") && playersRes.results.rows > 0) {
            const table = document.getElementById("teamtable");

            const pitchers = playersRes.data.pitchers.data;

            for(let i = 0; i < pitchers.length; i++) {
                // Initialize the current player for brevity's sake.
                let player = pitchers[i];

                // Initialize the new row, then-
                let tr = document.createElement("tr");

                let playerData = JSON.stringify(player);

                $(tr).attr("data-player",playerData);

                let t = table;

                // -add it to the new table.
                t.appendChild(tr);

                let playerImg= document.createElement("td");

                playerImg.classList.add("imgcont","align-middle")

                let img = <ModalImage
                      className={`rosterimg`}
                      small={`${player.editionURL}`}
                      medium={`${player.editionURL}`}
                  />
                ReactDOM.render(img,playerImg)

                // Create the first cell in the new row. For now this is the name of the player
                // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                let playerCard = document.createElement("td");

                playerCard.setAttribute('style', 'white-space: pre;');

                playerCard.classList.add("align-middle");

                let roleService = 2; //NOTE: Temporary. Eventually the service will know what the playertype is by ID.

                let playerCardButton = document.createElement("button");

                playerCardButton.classList.add("btn","px-0","py-0","playerButton");

                playerCardButton.innerHTML = player.playerName;

                playerCardButton.onclick = openPlayerModal.bind(null,player.editionID,address,roleService,player.playerName);

                playerCard.appendChild(playerCardButton);

                // Placeholder stats for now.
                // TO DO: Actually insert player stats
                let era = document.createElement("td");

                era.classList.add("align-middle");

                let eraVal = player.era.toFixed(2);

                era.textContent = eraVal;

                let ip = document.createElement("td");

                ip.classList.add("align-middle");

                ip.textContent = player.inningsPitched.toFixed(1);

                let saves = document.createElement("td");

                saves.classList.add("align-middle");

                saves.textContent = player.saves;

                let so = document.createElement("td");

                so.classList.add("align-middle");

                so.textContent = player.strikeOuts;

                let wins = document.createElement("td");

                wins.classList.add("align-middle");

                wins.textContent = player.wins;

                // Then, now that everything's been initialized, add all the cells to the table row.
                tr.appendChild(playerImg);

                tr.appendChild(playerCard);

                tr.appendChild(era);

                tr.appendChild(wins);

                tr.appendChild(saves);

                tr.appendChild(ip);

                tr.appendChild(so);

                
            }

            const hitterTable = document.getElementById("hittertable");

            const hitters = playersRes.data.hitters.data;
            
            for(let i = 0; i < hitters.length; i++) {
                // Initialize the current player for brevity's sake.
                let player = hitters[i];

                // Initialize the new row, then-
                let tr = document.createElement("tr");

                let playerData = JSON.stringify(player);

                $(tr).attr("data-player",playerData);

                let t = hitterTable;

                // -add it to the new table.
                t.appendChild(tr);

                let playerImg= document.createElement("td");

                playerImg.classList.add("imgcont","align-middle")

                let img = <ModalImage
                      className={`rosterimg`}
                      small={`${player.editionURL}`}
                      medium={`${player.editionURL}`}
                  />
                ReactDOM.render(img,playerImg)

                // Create the first cell in the new row. For now this is the name of the player
                // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                let playerCard = document.createElement("td");

                playerCard.setAttribute('style', 'white-space: pre;');

                playerCard.classList.add("align-middle");

                let roleService = 1; //NOTE: Temporary. Eventually the service will know what the playertype is by ID.

                let playerCardButton = document.createElement("button");

                playerCardButton.classList.add("btn","px-0","py-0","playerButton");

                playerCardButton.innerHTML = player.playerName;

                playerCardButton.onclick = openPlayerModal.bind(null,player.editionID,address,roleService,player.playerName);

                playerCard.appendChild(playerCardButton);

                // Placeholder stats for now.
                // TO DO: Actually insert player stats
                let ba = document.createElement("td");

                ba.classList.add("align-middle");

                let baVal = player.ba

                ba.textContent = baVal;

                let rbi = document.createElement("td");

                rbi.classList.add("align-middle");

                rbi.textContent = player.rbi;

                let runs = document.createElement("td");

                runs.classList.add("align-middle");

                runs.textContent = player.runs;

                let hr = document.createElement("td");

                hr.classList.add("align-middle");

                hr.textContent = player.homeRuns;

                let sb = document.createElement("td");

                sb.classList.add("align-middle");

                sb.textContent = player.stolenBases;

                // Then, now that everything's been initialized, add all the cells to the table row.
                tr.appendChild(playerImg);

                tr.appendChild(playerCard);

                tr.appendChild(ba);

                tr.appendChild(hr);

                tr.appendChild(runs);

                tr.appendChild(rbi);

                tr.appendChild(sb);
            }

            if(pitchers.length > 0) {
                // Initialize the current player for brevity's sake.
                let player = playersRes.data.pitcherTotals.data[0];

                // Initialize the new row, then-
                let tr = document.createElement("tr");

                tr.classList.add("table-secondary")

                tr.style.fontWeight = "bold";

                let playerData = JSON.stringify(player);

                $(tr).attr("data-player",playerData);

                let t = table;

                // -add it to the new table.
                t.appendChild(tr);

                // Placeholder stats for now.
                // TO DO: Actually insert player stats
                let emptyImg = document.createElement("td")

                let emptyName = document.createElement("td")

                emptyName.textContent = "Totals"

                let era = document.createElement("td");

                era.classList.add("align-middle");

                let eraVal = player.era.toFixed(2);

                era.textContent = eraVal;

                let ip = document.createElement("td");

                ip.classList.add("align-middle");

                ip.textContent = player.inningsPitched.toFixed(1);

                let saves = document.createElement("td");

                saves.classList.add("align-middle");

                saves.textContent = player.saves;

                let so = document.createElement("td");

                so.classList.add("align-middle");

                so.textContent = player.strikeOuts;

                let wins = document.createElement("td");

                wins.classList.add("align-middle");

                wins.textContent = player.wins;

                // Then, now that everything's been initialized, add all the cells to the table row.
                tr.appendChild(emptyImg);

                tr.appendChild(emptyName);

                tr.appendChild(era);

                tr.appendChild(wins);

                tr.appendChild(saves);

                tr.appendChild(ip);
                
                tr.appendChild(so);
            }

            if(hitters.length > 0) {
                // Initialize the current player for brevity's sake.
                let player = playersRes.data.hitterTotals.data[0];

                // Initialize the new row, then-
                let tr = document.createElement("tr");

                tr.classList.add("table-secondary")

                tr.style.fontWeight = "bold";

                let playerData = JSON.stringify(player);

                $(tr).attr("data-player",playerData);

                let t = hitterTable;

                // -add it to the new table.
                t.appendChild(tr);

                // Placeholder stats for now.
                // TO DO: Actually insert player stats
                let emptyImg = document.createElement("td")

                let emptyName = document.createElement("td")

                emptyName.textContent = "Totals"

                let ba = document.createElement("td");

                ba.classList.add("align-middle");

                let baVal = player.ba

                ba.textContent = baVal;

                let rbi = document.createElement("td");

                rbi.classList.add("align-middle");

                rbi.textContent = player.rbi;

                let runs = document.createElement("td");

                runs.classList.add("align-middle");

                runs.textContent = player.runs;

                let hr = document.createElement("td");

                hr.classList.add("align-middle");

                hr.textContent = player.homeRuns;

                let sb = document.createElement("td");

                sb.classList.add("align-middle");

                sb.textContent = player.stolenBases;

                // Then, now that everything's been initialized, add all the cells to the table row.
                tr.appendChild(emptyImg);

                tr.appendChild(emptyName);

                tr.appendChild(ba);

                tr.appendChild(hr);

                tr.appendChild(runs);

                tr.appendChild(rbi);
                
                tr.appendChild(sb);
            }

            let name = playersRes.data.teamInfo.data[0].teamName;

            const owneraddress = playersRes.data.teamInfo.data[0].user

            if(name == owneraddress) {
              name = truncateEthAddress(name);
            }

            setTeamName(name)
        }
    })
    }
  }

  // Runs on pageload if the user is authenticated. Does a lot of things: Fills in team name, team logo, alongside the team table itself.
  /*React.useEffect(async () => {
      //console.log('location', location.pathname); // { key: "3uu089" }
      // Fire whatever function you need.

      // If it's a valid session, and it can find the roster element
      // (this is necessary because if you're just flipping through pages quickly it will rapidly build up errors and freeze the app as it is not finding the element in question)
      fillTable();
  }, []);*/

  /*React.useEffect(async () => {
    await clearTable("hittertable");

    await clearTable("teamtable");

    await fillTable()
  }, [props.match.params.address]);*/

  React.useEffect(async () => {
    await clearTable("hittertable");

    await clearTable("teamtable");

    await fillTable()
  }, [address]);

  React.useEffect(async () => {

    //console.log("Starting")
    
    if(document.getElementById("gameLogBody") && showPlayerModal) {
      //console.log("Found gamelogbody")
      const body = document.getElementById("gameLogBody");

      let remove = body.children;

      for(let i = 0; i < remove.length; i++) {
        body.removeChild(remove[i]);
      }
      //console.log("Removed prior children")

      const gamelog = playerObject.gameLog;

      //console.log(gamelog);

      if(gamelog.length > 0) {
        //console.log("Starting for loop");
        for(let i = 0; i < gamelog.length; i++) {
          //console.log("New game in log");
          let game = gamelog[i];

          let tr = document.createElement("tr");

          let date = document.createElement("td");

          date.classList.add("compositeCell");

          let statDate = game.statDate.substring(2);

          //console.log(statDate);

          date.textContent = statDate;

          tr.appendChild(date);

          let composite = document.createElement("td");

          composite.classList.add("compositeCell");

          composite.textContent = `${game.mlbName} (${game.compositeNumber})`;

          tr.appendChild(composite);

          //console.log(game);

          if(playerObject.role == 1) {
            let ba = document.createElement("td");

            ba.classList.add("compositeCell");

            let baVal = game.ba;

            if(baVal == null) {
              baVal = ".000"
            }

            ba.textContent = baVal;

            tr.appendChild(ba);

            let rbi = document.createElement("td");

            rbi.classList.add("compositeCell")

            rbi.textContent = game.rbi;

            tr.appendChild(rbi)

            let runs = document.createElement("td");

            runs.classList.add("compositeCell");

            runs.textContent = game.runs;

            tr.appendChild(runs)

            let hr = document.createElement("td");

            hr.classList.add("compositeCell")

            hr.textContent = game.homeRuns;

            tr.appendChild(hr)

            let sb = document.createElement("td");

            sb.classList.add("compositeCell")

            sb.textContent = game.stolenBases;

            tr.appendChild(sb);
          } else {
            let era = document.createElement("td");

            era.classList.add("compositeCell");

            let eraVal = game.era;

            if(eraVal == null) {
              eraVal = "0.00"
            } else {
              eraVal = Number(game.era).toFixed(2)
            }

            era.textContent = eraVal;

            tr.appendChild(era);

            let ip = document.createElement("td");

            ip.classList.add("compositeCell")

            ip.textContent = game.inningsPitched;

            tr.appendChild(ip);

            let saves = document.createElement("td");

            saves.classList.add("compositeCell")

            saves.textContent = game.saves;

            tr.appendChild(saves);

            let so = document.createElement("td");

            so.classList.add("compositeCell")

            so.textContent = game.strikeOuts;

            tr.appendChild(so)

            let wins = document.createElement("td");

            wins.classList.add("compositeCell");

            wins.textContent = game.wins;

            tr.appendChild(wins);
          }
          
          body.appendChild(tr);
        }
      }
    }
    
  }, [showPlayerModal]);


  return (
    <div className="background-image-div">
        {redirect && (
          <Navigate to="/league" replace={true} />
        )}
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">
        <div className="div-content mx-auto mt-5" id="teamstats">
          <div className="table-responsive h-75 w-sm-50 w-100">
            <table className="table table-light table-hover">
              <thead className="table-secondary">
                <tr className="table-dark"><th colSpan={7}><h2 className="tableHeader">Hitter Stats - {teamName}</h2></th></tr>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>BA</th>
                    <th>HR</th>
                    <th>Runs</th>
                    <th>RBI</th>
                    <th>SB</th>
                  </tr>
              </thead>
              <tbody id="hittertable">
                
              </tbody>
            </table>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            
            <table className="mt-4 table table-light table-hover">
              <thead>
                <tr className="table-dark"><th colSpan={7}><h2 className="tableHeader">Pitcher Stats - {teamName}</h2></th></tr>
                  <tr className="table-secondary">
                    <th></th>
                    <th>Name</th>
                    <th>ERA</th>
                    <th>Wins</th>
                    <th>Saves</th>
                    <th>IP</th>
                    <th>SO</th>
                    
                    
                  </tr>
              </thead>
              <tbody id="teamtable">
                
              </tbody>
            </table>

            
          </div>
        </div>
        <Modal show={showCompositeModal} onHide={closeAllModals} contentClassName="text-light bg-dark" className={"mt-5 "}>
          <Modal.Header>
            <Modal.Title className="highlightText">{compositeObject.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Player schedule data is coming soon!
          </Modal.Body>
          <Modal.Footer>
              <button className="btn btn-danger me-auto" onClick={async () => {await closeAllModals();}}>Close</button>
          </Modal.Footer>
        </Modal>
        <Modal show={showPlayerModal} onHide={closeAllModals} contentClassName="text-light bg-light compositeModal " className={""}>
          <Modal.Header className="bg-dark">
            <Modal.Title style={{color:"rgb(0, 163, 41)"}}>{playerObject.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive table-bordered ">
              <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                <thead>
                  <tr><th className="" style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"7"}>Total Stats</th></tr>
                    {showPitcherStats? (
                      <tr className="table-secondary">
                        <th className="compositeCell">ERA</th>
                        <th className="compositeCell">IP</th>
                        <th className="compositeCell">Saves</th>
                        <th className="compositeCell">SO</th>
                        <th className="compositeCell">Wins</th>
                      </tr>
                    )
                    : (
                      <tr className="table-secondary">
                        <th className="compositeCell">BA</th>
                        <th className="compositeCell">RBI</th>
                        <th className="compositeCell">Runs</th>
                        <th className="compositeCell">HR</th>
                        <th className="compositeCell">SB</th>
                      </tr>
                    )}
                </thead>
                <tbody>
                  {showPitcherStats? (
                    <tr>
                      <td className="compositeCell">{playerObject.era}</td>
                      <td className="compositeCell">{playerObject.inningsPitched}</td>
                      <td className="compositeCell">{playerObject.saves}</td>
                      <td className="compositeCell">{playerObject.strikeOuts}</td>
                      <td className="compositeCell">{playerObject.wins}</td>
                    </tr>
                  )
                  : (
                    <tr>
                      <td className="compositeCell">{playerObject.ba}</td>
                      <td className="compositeCell">{playerObject.rbi}</td>
                      <td className="compositeCell">{playerObject.runs}</td>
                      <td className="compositeCell">{playerObject.homeRuns}</td>
                      <td className="compositeCell">{playerObject.stolenBases}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
            <div className="mt-5 table-responsive table-bordered modalTableOverflow">
              <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                <thead>
                  <tr><th style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"7"}>Game Log</th></tr>
                    {showPitcherStats? (
                      <tr className="table-secondary">
                        <th className="compositeCell">Date</th>
                        <th className="compositeCell">Composite</th>
                        <th className="compositeCell">ERA</th>
                        <th className="compositeCell">IP</th>
                        <th className="compositeCell">Saves</th>
                        <th className="compositeCell">SO</th>
                        <th className="compositeCell">Wins</th>
                      </tr>
                    )
                    : (
                      <tr className="table-secondary">
                        <th className="compositeCell">Date</th>
                        <th className="compositeCell">Composite</th>
                        <th className="compositeCell">BA</th>
                        <th className="compositeCell">RBI</th>
                        <th className="compositeCell">Runs</th>
                        <th className="compositeCell">HR</th>
                        <th className="compositeCell">SB</th>
                      </tr>
                    )}
                </thead>
                <tbody id="gameLogBody">
                </tbody>
              </table>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
              <button className="btn btn-danger me-auto" onClick={async () => {await closeAllModals();}}>Close</button>
          </Modal.Footer>
        </Modal>

        <div className="pagebottom"></div>
      </div>
    </div>
  );
};

export default TeamStats;