import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";
//import Moralis from "moralis";
import { useLocation, Navigate } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import Dropzone from "react-dropzone";
import $ from "jquery";
import axios from "axios";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import ModalImage from "react-modal-image"
import TruncateEthAddress from "truncate-eth-address";

const qs = require("qs");
const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;
const clearTable = functions.clearTable;

const LeagueStats = () => {
  const [showTeamModal,setShowTeamModal] = React.useState(false);

  const [teamObject, setTeamObject] = React.useState({id: 0});

  const [currentLeague, setCurrentLeague] = React.useState(0);

  const [currentLeagueName, setCurrentLeagueName] = React.useState("");

  const [showLeagueDropdown, setShowLeagueDropdown] = React.useState(false);

  const [address, setAddress] = React.useState("");

  const [redirect, setRedirect] = React.useState(false);

  const location = useLocation();

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.session.currentUser);

  async function closeAllModals() {
    setShowTeamModal(false);
  }

  function redirectToTeamstats(add) {
    setAddress(add);
    setRedirect(true);
  }

  async function fillTable() {
    if(document.getElementById("leaguestats")) {
    
      // Grab the team data of the user in question
      
      const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Leagues.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=400&ca=${env.REACT_APP_COLLECTIONADD}&lid=${currentLeague}`).then(async (result) => {
          // Then, if the user has any players, grab them and put them into the table.

          console.log(result);
          
          if(document.getElementById("hittertable") && result.data.length > 0) {
              const hitterTable = document.getElementById("hittertable");

              const pitcherTable = document.getElementById("pitchertable");

              let row = 0;

              let hitterArray = [];

              let pitcherArray = [];

              for(let i = 0; i < result.data.length; i++) {
                  let team = result.data[i];
                  hitterArray.push(team);

                  pitcherArray.push(team);
              }

              hitterArray.sort(function(a,b) {return Number(b.battingAverage) - Number(a.battingAverage)});

              pitcherArray.sort(function(a,b) {return Number(a.earnedRunAverage) - Number(b.earnedRunAverage) });

              // For each player, create a table row and fill them with.
              for(let i = 0; i < result.data.length; i++) {
                  console.log("new row");
                  // Initialize the current player for brevity's sake.
                  //let player = result.data[i];

                  let hitter = hitterArray[i];

                  let pitcher = pitcherArray[i];

                  row += 1;

                  let hAddress = hitter.ownerAddress;

                  let pAddress = pitcher.ownerAddress;

                  let hName = hitter.teamName;

                  let pName = pitcher.teamName;

                  if(hName == hAddress) {
                      hName = TruncateEthAddress(hName);
                  }

                  if(pName == pAddress) {
                      pName = TruncateEthAddress(pName);
                  }

                  // Initialize the new row, then-
                  let htr = document.createElement("tr");

                  let ptr = document.createElement("tr");

                  //let playerData = JSON.stringify(player);

                  // -make sure it has an ID we can refer to it by, then,-
                  htr.id = `h${row.toString()}`;

                  ptr.id = `p${row.toString()}`;

                  let ht = hitterTable;

                  let pt = pitcherTable;

                  // -add it to the new table.
                  ht.appendChild(htr);

                  pt.appendChild(ptr);

                  let hTeamImage = document.createElement("td");

                  hTeamImage.classList.add("imgcont","align-middle")

                  let himg = <ModalImage
                          className={`rosterimg`}
                          small={`${hitter.teamLogo}`}
                          medium={`${hitter.teamLogo}`}
                      />
                  ReactDOM.render(himg,hTeamImage)

                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.

                  let hplayerCard = document.createElement("td");

                  hplayerCard.setAttribute('style', 'white-space: pre;');

                  hplayerCard.classList.add("align-middle");

                  let hplayerCardButton = document.createElement("a");

                  hplayerCardButton.classList.add("btn","px-0","py-0","playerButton");

                  hplayerCardButton.href = `/#/teamstats/${hAddress}`
  
                  hplayerCardButton.innerHTML = hName;

                  //hplayerCardButton.onclick = redirectToTeamstats.bind(null,address);

                  hplayerCard.appendChild(hplayerCardButton);

                  let ba = document.createElement("td");

                  ba.classList.add("align-middle");

                  ba.innerHTML = `${hitter.battingAverage}`

                  let sb = document.createElement("td");

                  sb.classList.add("align-middle");

                  sb.innerHTML = `${hitter.stolenBases}`

                  let rbi = document.createElement("td");

                  rbi.classList.add("align-middle");

                  rbi.innerHTML = `${hitter.rbi}`

                  let runs = document.createElement("td");

                  runs.classList.add("align-middle");

                  runs.innerHTML = `${hitter.runs}`

                  let hr = document.createElement("td");

                  hr.classList.add("align-middle");

                  hr.innerHTML = `${hitter.homeRuns}`;

                  htr.appendChild(hTeamImage);

                  htr.appendChild(hplayerCard);

                  htr.appendChild(ba);

                  htr.appendChild(hr);

                  htr.appendChild(runs);

                  htr.appendChild(rbi);

                  htr.appendChild(sb);

                  let pTeamImage = document.createElement("td");

                  pTeamImage.classList.add("imgcont","align-middle")

                  let pimg = <ModalImage
                          className={`rosterimg`}
                          small={`${pitcher.teamLogo}`}
                          medium={`${pitcher.teamLogo}`}
                      />
                  ReactDOM.render(pimg,pTeamImage)

                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                  let pplayerCard = document.createElement("td");

                  pplayerCard.setAttribute('style', 'white-space: pre;');

                  pplayerCard.classList.add("align-middle");

                  let pplayerCardButton = document.createElement("a");

                  pplayerCardButton.classList.add("btn","px-0","py-0","playerButton");

                  pplayerCardButton.href = `/teamstats/${pAddress}`
  
                  pplayerCardButton.innerHTML = pName;

                  pplayerCard.appendChild(pplayerCardButton);

                  let so = document.createElement("td");

                  so.classList.add("align-middle");

                  so.innerHTML = `${pitcher.strikeOuts}`

                  let ip = document.createElement("td");

                  ip.classList.add("align-middle");

                  ip.innerHTML = `${pitcher.inningsPitched}`

                  let sv = document.createElement("td");

                  sv.classList.add("align-middle");

                  sv.innerHTML = `${pitcher.saves}`

                  let era = document.createElement("td");

                  era.classList.add("align-middle");

                  era.innerHTML = `${pitcher.earnedRunAverage.toFixed(2)}`

                  let wins = document.createElement("td");

                  wins.classList.add("align-middle");

                  wins.innerHTML = `${pitcher.wins}`

                  ptr.appendChild(pTeamImage);

                  ptr.appendChild(pplayerCard);

                  ptr.appendChild(era);

                  ptr.appendChild(wins);

                  ptr.appendChild(sv);

                  ptr.appendChild(ip);

                  ptr.appendChild(so);
              }
          }
      
      })
    }
  }

  async function changeLeague(name, id) {
    setCurrentLeague(id);
    setCurrentLeagueName(name);
  }

  // Runs on pageload if the user is authenticated. Does a lot of things: Fills in team name, team logo, alongside the team table itself.
  React.useEffect(async () => {
    const q = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Leagues.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=401&ca=${env.REACT_APP_COLLECTIONADD}`).then(async leagueRes => {
      console.log(leagueRes);
      let defaultLeague = 1;
      let defaultLeagueName = leagueRes.data[0].leagueName;
      if(leagueRes.data.length > 1) {
        setShowLeagueDropdown(true);
      } else {
        setCurrentLeagueName(defaultLeagueName);
        setCurrentLeague(defaultLeague);
        
      }
    })
    
  }, []);

  React.useEffect(async () => {
    if(showLeagueDropdown) {
      const q = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Leagues.php?apiKey=${env.REACT_APP_INDEXAPIKEY}&type=401&ca=${env.REACT_APP_COLLECTIONADD}`).then(async leagueRes => {
        //console.log(leagueRes);
        const leagues = document.getElementById("leagueMenu");
        let defaultLeague = 1;
        let defaultLeagueName = leagueRes.data[0].leagueName;
        if(leagueRes.data.length > 1) {
          for(let i = 0; i < leagueRes.data.length; i++) {
            let league = leagueRes.data[i];
    
            let li = document.createElement("li");
    
            let button = document.createElement("a");
    
            button.classList.add("dropdown-item");
    
            li.appendChild(button);
    
            button.textContent = league.leagueName;
    
            button.onclick = changeLeague.bind(null,league.leagueName,league.leagueID);

            button.type = "button"

            leagues.appendChild(button);
          }
          
          if(currentUser) {
            console.log("logged in")
            const b = fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${currentUser}&c=${env.REACT_APP_COLLECTIONADD}`).then(async userRes => {
              defaultLeague = userRes.data[0].leagueID
            })
          }
        }
        setCurrentLeague(defaultLeague);
        setCurrentLeagueName(defaultLeagueName);
      })
    }
    
    
  }, [showLeagueDropdown]);

  React.useEffect(async () => {
    clearTable("hittertable");
    clearTable("pitchertable");
    await fillTable();
  }, [currentLeague]);

  return (
    <div className="background-image-div">
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      {redirect && (
          <Navigate to={`/teamstats/${address}`} replace={true} />
        )}
      <div className="div-background-content">
        <div className="div-content mx-auto mt-5" id="leaguestats">
          {showLeagueDropdown && (<div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle mb-5" type="button" data-bs-toggle="dropdown" aria-expanded="false">
              {currentLeagueName}
            </button>
            <ul className="dropdown-menu" id="leagueMenu">
            </ul>
          </div>)}
          <div className="table-responsive h-75 w-sm-50 w-100">
            <table className="table table-light" id="htable">
              <thead className="table-secondary">
                <tr className="table-dark"><th colSpan={8}><h2 className="tableHeader">Hitter Stats - {currentLeagueName} League</h2></th></tr>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>BA</th>
                    <th>HR</th>
                    <th>Runs</th>
                    <th>RBI</th>
                    <th>SB</th>
                  </tr>
              </thead>
              <tbody id="hittertable">
                
              </tbody>
            </table>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            <table className="table table-light">
              <thead className="table-secondary">
                <tr className="table-dark"><th colSpan={8}><h2 className="tableHeader">Pitcher Stats - {currentLeagueName} League</h2></th></tr>
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>ERA</th>
                    <th>Wins</th>
                    <th>Saves</th>
                    <th>IP</th>
                    <th>SO</th>
                  </tr>
              </thead>
              <tbody id="pitchertable">
                
              </tbody>
            </table>
          </div>
        </div>
        <Modal show={showTeamModal} onHide={closeAllModals} contentClassName="text-light bg-light compositeModal" className={""}>
          <Modal.Header className="bg-dark">
            <Modal.Title style={{color:"rgb(0, 163, 41)"}}>{teamObject.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive table-bordered">
              <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                <thead>
                  <tr><th className="" style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"5"}>Total Stats</th></tr>
                    
                    <tr className="table-secondary">
                      <th className="compositeCell">ERA</th>
                      <th className="compositeCell">Innings Pitched</th>
                      <th className="compositeCell">Saves</th>
                      <th className="compositeCell">Strike Outs</th>
                      <th className="compositeCell">Wins</th>
                    </tr>
                    
                </thead>
                <tbody>            
                  <tr>
                    <td className="compositeCell">{teamObject.era}</td>
                    <td className="compositeCell">{teamObject.inningsPitched}</td>
                    <td className="compositeCell">{teamObject.saves}</td>
                    <td className="compositeCell">{teamObject.strikeOuts}</td>
                    <td className="compositeCell">{teamObject.wins}</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
        
            <div className="mt-4 table-responsive table-bordered">
                <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                    <thead>
                      <tr><th style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"7"}>Game Log</th></tr>
                      
                      <tr className="table-secondary">
                          <th className="compositeCell">Date</th>
                          <th className="compositeCell">Composite</th>
                          <th className="compositeCell">ERA</th>
                          <th className="compositeCell">IP</th>
                          <th className="compositeCell">Saves</th>
                          <th className="compositeCell">SO</th>
                          <th className="compositeCell">Wins</th>
                      </tr>
                    </thead>
                    <tbody id="gameLogBody">
                    </tbody>
                </table>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
              <button className="btn btn-danger me-auto" onClick={async () => {await closeAllModals();}}>Close</button>
          </Modal.Footer>
        </Modal>

        <div className="pagebottom"></div>
      </div>
    </div>
  );
};


export default LeagueStats;