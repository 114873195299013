import React from "react";
import ReactDOM from "react-dom";
//import { useMoralis } from "react-moralis";
//import Moralis from "moralis";
import { useLocation, Navigate } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import Dropzone from "react-dropzone";
import $ from "jquery";
import axios from "axios";
import * as functions from "./functions.js";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "react-bootstrap";
import ModalImage from "react-modal-image"
import TruncateEthAddress from "truncate-eth-address";

const qs = require("qs");
const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;
const clearTable = functions.clearTable;

const Composites = () => {
  const [showCompositeModal,setShowCompositeModal] = React.useState(false);

  const [compositeObject, setCompositeObject] = React.useState({id:0});

  const [compositeModalData,setCompositeModalData] = React.useState(false);

  const [showPlayerModal,setShowPlayerModal] = React.useState(false);

  const [showPitcherStats, setShowPitcherStats] = React.useState(false);

  const [playerObject, setPlayerObject] = React.useState({id: 0});

  const [tablePage, setTablePage] = React.useState(1);

  const [disablePrev, setDisablePrev] = React.useState(true);

  const [disableNext, setDisableNext] = React.useState(false);

  const location = useLocation();

  const dispatch = useDispatch();

  async function openCompositeModal(objString) {
    const obj = JSON.parse(objString);
    console.log(obj);

    await closeAllModals();

    const newObject = {
      name: `${obj.fname} ${obj.lname}`,

      teamID: obj.teamID,

      status: obj.statusDescription,

      extra: obj.xtraInfo
    };

    if(obj.statusCode == null) {
      newObject.status = "Not on 40-Man Roster";
    }

    setCompositeModalData(false);

    if(obj.xtraInfo.length > 0) {
      setCompositeModalData(true);
    }

    setCompositeObject(newObject);

    setShowCompositeModal(true);
  }

  async function openPlayerModal(id, owner,role, playerName) {
    //console.log(id);

    await closeAllModals();

    const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Players.php?apiKey=${env.REACT_APP_PLAYERAPIKEY}&type=2&ei=${id}&oa=${owner}&ca=${env.REACT_APP_COLLECTIONADD}&hp=${role}`).then(async results => {
      const player = results.data;

      const totals = player.totals.data[0];

      //console.log(results);

      const newObject = {
        id: id,
        name: playerName,
        role: role,

        ba: ".000",
        homeRuns: 0,
        rbi: 0,
        runs: 0,
        stolenBases: 0,

        era: "0.00",
        inningsPitched: 0,
        strikeOuts: 0,
        saves: 0,
        wins: 0,

        gameLog: []
      };

      if(player.totals.data.length > 0) {
        if(role == 1) { // Hitter
          newObject.ba = totals.ba;
  
          if(newObject.ba == null) {
            newObject.ba = ".000";
          }
  
          newObject.homeRuns = totals.homeRuns;
  
          newObject.rbi = totals.rbi;
  
          newObject.runs = totals.runs;
  
          newObject.stolenBases = totals.stolenBases;
  
          setShowPitcherStats(false);
        } else { // Pitcher
          newObject.era = Number(totals.era).toFixed(2);
  
          if(totals.era == null) {
            newObject.era = ".00";
          }
  
          newObject.inningsPitched = totals.inningsPitched;
  
          newObject.saves = totals.saves;
  
          newObject.strikeOuts = totals.strikeOuts;
  
          newObject.wins = totals.wins;
  
          setShowPitcherStats(true);
        }
      }
      

      newObject.gameLog = player.gameLog.data
  
      setPlayerObject(newObject);
  
      setShowPlayerModal(true);
    }).catch(err => {
      console.log(err);
    })
    
  }

  async function closeAllModals() {
    setShowCompositeModal(false);

    setShowPlayerModal(false);
  }

  async function fillTable() {
    if(document.getElementById("composites")) {

      setDisableNext(true);

      setDisablePrev(true);

      let disprev = false;

      let disnext = false;

      if(tablePage == 1) {
        disprev = true;
      }
    
      // Grab the team data of the user in question
      const a = await fetchAsync(`${env.REACT_APP_BASEURLSERVICE}/td_Players.php?apiKey=${env.REACT_APP_PLAYERAPIKEY}&type=1&pg=${tablePage}`).then(async (result) => {
          // Then, if the user has any players, grab them and put them into the table.

          console.log(result);
          if(result.data.length < 25) {
            disnext = true;
          }
          
          if(document.getElementById("compositetable") && result.data.length > 0) {
              const table = document.getElementById("compositetable");

              const hitterTable = document.getElementById("hittertable");

              const relieverTable = document.getElementById("relievertable")

              let row = 0;

              let todayComposite = result.data[0].compositeNumber;
              // For each player, create a table row and fill them with.
              for(let i = 0; i < result.data.length; i++) {
                  console.log("new row");
                  // Initialize the current player for brevity's sake.
                  let player = result.data[i];

                  const composites = player.compositeDetails;
                  row += 1;

                  let address = player.ownerAddress;

                  let name = player.playerName

                  let ownerName = player.teamName;

                  if(ownerName.toLowerCase() == address.toLowerCase()) {
                    ownerName = TruncateEthAddress(ownerName);
                  }

                  let edition = player.editionID;

                  // Initialize the new row, then-
                  let tr = document.createElement("tr");

                  let playerData = JSON.stringify(player);

                  $(tr).attr("data-player",playerData);

                  // -make sure it has an ID we can refer to it by, then,-
                  tr.id = row.toString();

                  let t = table;

                  if(player.compositeType == "hitter") {
                    t = hitterTable;
                  } else if(player.role == "reliever") {
                    t = relieverTable
                  }

                  // -add it to the new table.
                  t.appendChild(tr);

                  let playerImg= document.createElement("td");

                  playerImg.classList.add("imgcont","align-middle")

                  let img = <ModalImage
                          className={`rosterimg`}
                          small={`${player.editionURL}`}
                          medium={`${player.editionURL}`}
                      />
                  ReactDOM.render(img,playerImg)

                  let editionCard = document.createElement("td");

                  editionCard.setAttribute('style', 'white-space: pre;');

                  editionCard.classList.add("align-middle");

                  editionCard.innerHTML = `#${edition}`

                  // Create the first cell in the new row. For now this is the name of the player
                  // TO DO: In the future, maybe make the IMAGE of the player the first cell, then have the player's name as the second.
                  let playerCard = document.createElement("td");

                  playerCard.setAttribute('style', 'white-space: pre;');

                  playerCard.classList.add("align-middle");

                  let toggletype = player.compositeType

                  let playerType = player.compositeType;

                  let role = "";

                  let roleService = 1; //NOTE: Temporary. Eventually the service will know what the playertype is by ID.

                  if(playerType == "pitcher") {
                      roleService = 2;
                      if(player.role == "reliever") {
                      role = "Relief ";
                      }
                      else {
                      role = "Starting "
                      }
                  }

                  playerType = playerType.charAt(0).toUpperCase() + playerType.slice(1);

                  let playerCardButton = document.createElement("button");

                  playerCardButton.classList.add("btn","px-0","py-0","playerButton");

                  playerCardButton.innerHTML = player.playerName;

                  playerCardButton.onclick = openPlayerModal.bind(null,player.editionID,address,roleService,player.playerName);

                  playerCard.appendChild(playerCardButton);

                  let teamCard = document.createElement("td");

                  teamCard.setAttribute('style', 'white-space: pre;');

                  teamCard.classList.add("align-middle");

                  let teamCardButton = document.createElement("a");

                  teamCardButton.classList.add("btn","px-0","py-0","playerButton");

                  teamCardButton.href = `/#/teamstats/${address}`;
  
                  teamCardButton.innerHTML = ownerName;

                  teamCard.appendChild(teamCardButton);

                  let statOne = document.createElement("td");

                  statOne.classList.add("align-middle");

                  let statusOne = "";

                  if(composites.composite1.statusCode) {

                    if((composites.composite1.statusCode == "A" && composites.composite1.xtraInfo.length == 0) || composites.composite1.statusCode != "A") {
                      statusOne = `<span class="material-symbols-outlined iconFilled text-warning">report</span> `
                    }
                    if(composites.composite1.statusCode.includes("D")) {
                      statusOne = `<span class="material-symbols-outlined iconFilled text-danger">warning</span> `
                    }
                  }
                  else {
                    statusOne = `<span class="material-symbols-outlined iconFilled text-danger">warning</span> `
                  }

                  let statOneButton = document.createElement("button");

                  statOneButton.classList.add("btn","px-0","py-0")
  
                  statOneButton.innerHTML = `${statusOne}${composites.composite1.fname} ${composites.composite1.lname} (${composites.composite1.composite1Value})`;

                  statOneButton.onclick = openCompositeModal.bind(null,JSON.stringify(composites.composite1));

                  statOne.appendChild(statOneButton);
  
                  let statTwo = document.createElement("td");

                  statTwo.classList.add("align-middle");

                  let statusTwo = "";

                  if(composites.composite2.statusCode) {
                    if((composites.composite2.statusCode == "A" && composites.composite2.xtraInfo.length == 0) || composites.composite2.statusCode != "A") {
                      statusTwo = `<span class="material-symbols-outlined iconFilled text-warning">report</span> `
                    }
                    if(composites.composite2.statusCode.includes("D")) {
                      statusTwo = `<span class="material-symbols-outlined iconFilled text-danger">warning</span> `
                    }
                  }
                  else {
                    statusTwo = `<span class="material-symbols-outlined iconFilled text-danger">warning</span> `
                  }
  
                  let statTwoButton = document.createElement("button");

                  statTwoButton.classList.add("btn","px-0","py-0")
  
                  statTwoButton.innerHTML = `${statusTwo}${composites.composite2.fname} ${composites.composite2.lname} (${composites.composite2.composite2Value})`;

                  statTwoButton.onclick = openCompositeModal.bind(null,JSON.stringify(composites.composite2));

                  statTwo.appendChild(statTwoButton);
  
                  let statThree = document.createElement("td");

                  statThree.classList.add("align-middle");

                  let statusThree = "";

                  if(composites.composite3.statusCode) {
                    if((composites.composite3.statusCode == "A" && composites.composite3.xtraInfo.length == 0) || composites.composite3.statusCode != "A") {
                      statusThree = `<span class="material-symbols-outlined iconFilled text-warning">report</span> `
                    }

                    if(composites.composite3.statusCode.includes("D")) {
                      statusThree = `<span class="material-symbols-outlined iconFilled text-danger">warning</span> `
                    }
  
                    
                  }
                  else {
                    statusThree = `<span class="material-symbols-outlined iconFilled text-danger">warning</span> `
                  }
  
                  let statThreeButton = document.createElement("button");

                  statThreeButton.classList.add("btn","px-0","py-0")
  
                  statThreeButton.innerHTML = `${statusThree}${composites.composite3.fname} ${composites.composite3.lname} (${composites.composite3.composite3Value})`;

                  statThreeButton.onclick = openCompositeModal.bind(null,JSON.stringify(composites.composite3));

                  statThree.appendChild(statThreeButton);

                  let today;

                  let tomorrow;

                  switch(todayComposite) {
                      case 2:
                      today = statTwoButton;
                      tomorrow = statThreeButton;
                      break;
                      case 3:
                      today = statThreeButton;
                      tomorrow = statOneButton
                      break;
                      default:
                      today = statOneButton;
                      tomorrow = statTwoButton;
                  }

                  today.classList.add("today-text");

                  tomorrow.classList.add("tomorrow-text")

                  // Then, now that everything's been initialized, add all the cells to the table row.
                  tr.appendChild(playerImg);

                  tr.appendChild(editionCard)

                  tr.appendChild(playerCard);

                  tr.appendChild(teamCard);

                  tr.appendChild(statOne);

                  tr.appendChild(statTwo);

                  tr.appendChild(statThree);
              }
          }
      
      })

      setDisableNext(disnext);

      setDisablePrev(disprev);
    }
  }

  function decrement() {
    const val = tablePage;

    const newVal = tablePage - 1

    setTablePage(newVal);
  }

  function increment() {
    const val = tablePage;

    const newVal = tablePage + 1

    setTablePage(newVal);
  }
  React.useEffect(async () => {
    //scrollToHash();

    //console.log("Starting")
    
    if(document.getElementById("compositeContent") && showCompositeModal && compositeModalData) {
      //console.log("Found gamelogbody")
      const body = document.getElementById("compositeContent");

      body.innerHTML = "";
      //console.log("Removed prior children")

      const statusText = document.getElementById("statusText")

      statusText.classList.value = "";

      if(compositeObject.status == "Active") {
        statusText.classList.add("text-success")
      } else {
        statusText.classList.add("text-danger");
      }

      const compositeData = compositeObject.extra;

      for(let i = 0; i < compositeData.length; i++) {
        let data = compositeData[i];

        let obj = {};

        if(compositeObject.teamID == data.homeTeamID) {
          // Set obj data to AWAY team

          obj.opposingStarterName = data.awayStarterName;

          obj.opposingStarterTeam = data.awayTeamName;

          obj.opposingStarterStats = data.awayStarterStats;
        } else {
          // Set obj data to HOME team

          obj.opposingStarterName = data.homeStarterName;

          obj.opposingStarterTeam = data.homeTeamName;

          obj.opposingStarterStats = data.homeStarterStats;
        }

        const unadjustedDate = new Date(data.officialDate);

        const date = new Date(unadjustedDate.getTime() + Math.abs(unadjustedDate.getTimezoneOffset()*60000)).toDateString();

        const newDiv = document.createElement("div");

        newDiv.classList.add("table-responsive");

        body.appendChild(newDiv);

        const newTable = document.createElement("table");

        newTable.classList.add("table","table-hover","table-light","table-borderless");

        newDiv.appendChild(newTable);

        const thead = document.createElement("thead");

        newTable.appendChild(thead);

        const headerRow = document.createElement("tr");

        const header = document.createElement("th");

        header.setAttribute("colspan",3);

        header.style.backgroundColor = "rgb(152, 174, 156)";

        header.textContent = `${date} @ ${data.venueName}`;

        headerRow.appendChild(header);

        thead.appendChild(headerRow);

        const cellHeaderRow = document.createElement("tr");

        cellHeaderRow.classList.add("table-secondary");

        const nameHeader = document.createElement("th");

        nameHeader.classList.add("compositeCell");

        nameHeader.textContent = "Opposing Starter"

        const teamHeader = document.createElement("th");

        teamHeader.classList.add("compositeCell");

        teamHeader.textContent = "Team";

        const statHeader = document.createElement("th");

        statHeader.classList.add("compositeCell");

        statHeader.textContent = "Season to Date";

        cellHeaderRow.appendChild(nameHeader);

        cellHeaderRow.appendChild(teamHeader)

        cellHeaderRow.appendChild(statHeader);

        thead.appendChild(cellHeaderRow);

        const tbody = document.createElement("tbody");

        newTable.appendChild(tbody);

        const bodyRow = document.createElement("tr");

        const nameCell = document.createElement("td");

        nameCell.classList.add("compositeCell");

        nameCell.textContent = obj.opposingStarterName

        const teamCell = document.createElement("td");

        teamCell.classList.add("compositeCell");

        teamCell.textContent = obj.opposingStarterTeam;

        const statCell = document.createElement("td");

        statCell.classList.add("compositeCell");

        statCell.textContent = obj.opposingStarterStats;

        bodyRow.appendChild(nameCell);

        bodyRow.appendChild(teamCell)

        bodyRow.appendChild(statCell);

        tbody.appendChild(bodyRow);


      }
    }
    
  }, [showCompositeModal]);

  // Runs on pageload if the user is authenticated. Does a lot of things: Fills in team name, team logo, alongside the team table itself.
  React.useEffect(async () => {
    clearTable("compositetable");
    clearTable("hittertable");
    clearTable("relievertable")
    
    await fillTable();
  }, [tablePage]);

  React.useEffect(async () => {

    //console.log("Starting")
    
    if(document.getElementById("gameLogBody") && showPlayerModal) {
      //console.log("Found gamelogbody")
      const body = document.getElementById("gameLogBody");

      let remove = body.children;

      for(let i = 0; i < remove.length; i++) {
        body.removeChild(remove[i]);
      }
      //console.log("Removed prior children")

      const gamelog = playerObject.gameLog;

      //console.log(gamelog);

      if(gamelog.length > 0) {
        //console.log("Starting for loop");
        for(let i = 0; i < gamelog.length; i++) {
          //console.log("New game in log");
          let game = gamelog[i];

          let tr = document.createElement("tr");

          let date = document.createElement("td");

          date.classList.add("compositeCell");

          let statDate = game.statDate.substring(2);

          //console.log(statDate);

          date.textContent = statDate;

          tr.appendChild(date);

          let composite = document.createElement("td");

          composite.classList.add("compositeCell");

          composite.textContent = `${game.mlbName} (${game.compositeNumber})`;

          tr.appendChild(composite);

          //console.log(game);

          if(playerObject.role == 1) {
            let ba = document.createElement("td");

            ba.classList.add("compositeCell");

            let baVal = game.ba;

            if(baVal == null) {
              baVal = ".000"
            }

            ba.textContent = baVal;

            tr.appendChild(ba);

            let rbi = document.createElement("td");

            rbi.classList.add("compositeCell")

            rbi.textContent = game.rbi;

            tr.appendChild(rbi)

            let runs = document.createElement("td");

            runs.classList.add("compositeCell");

            runs.textContent = game.runs;

            tr.appendChild(runs)

            let hr = document.createElement("td");

            hr.classList.add("compositeCell")

            hr.textContent = game.homeRuns;

            tr.appendChild(hr)

            let sb = document.createElement("td");

            sb.classList.add("compositeCell")

            sb.textContent = game.stolenBases;

            tr.appendChild(sb);
          } else {
            let era = document.createElement("td");

            era.classList.add("compositeCell");

            let eraVal = game.era;

            if(eraVal == null) {
              eraVal = "0.00"
            } else {
              eraVal = Number(game.era).toFixed(2)
            }

            era.textContent = eraVal;

            tr.appendChild(era);

            let ip = document.createElement("td");

            ip.classList.add("compositeCell")

            ip.textContent = game.inningsPitched;

            tr.appendChild(ip);

            let saves = document.createElement("td");

            saves.classList.add("compositeCell")

            saves.textContent = game.saves;

            tr.appendChild(saves);

            let so = document.createElement("td");

            so.classList.add("compositeCell")

            so.textContent = game.strikeOuts;

            tr.appendChild(so)

            let wins = document.createElement("td");

            wins.classList.add("compositeCell");

            wins.textContent = game.wins;

            tr.appendChild(wins);
          }
          
          body.appendChild(tr);
        }
      }
    }
    
  }, [showPlayerModal]);

  return (
    <div className="background-image-div">
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">
        <div className="div-content mx-auto mt-5" id="composites">
          <div className="table-responsive h-75 w-sm-50 w-100">
              <table className="table table-light">
                <thead className="text-center">
                    <tr>
                      <th></th>
                      <td style={{width: "25%"}}><b>Today's Composite</b></td>
                      <td style={{width: "25%"}}><i>Tomorrow's Composite</i></td>
                      <td style={{width: "25%"}}>Yesterday's Composite</td>
                      <td style={{width: "25%"}} className="activeCard">Active Player</td>
                      
                    </tr>
                </thead>
              </table>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            <div className="button-div mb-2" style={{width: "100%"}}>
                <button id="decrement" className="amount-button me-2 btn btn-primary" disabled={disablePrev} onClick={async () => { decrement() }}>Prev</button>
                <p className="bg-dark" style={{width: "fit-content"}}>Page {tablePage}</p>
                <button id="increment" className="amount-button ms-2 btn btn-primary" disabled={disableNext} onClick={async () => { increment() }}>Next</button>
            </div>
            <table className="table table-light">
              <thead style={{backgroundColor: "#B9B9B9"}}>
                <tr className="table-dark"><th colSpan={8}><h2 className="tableHeader">Hitter Composites</h2></th></tr>
                  <tr>
                    <th></th>
                    <th>Edition #</th>
                    <th>Name</th>
                    <th>Owner</th>
                    <th>Bat/Batting Avg</th>
                    <th>Muscles/Home Runs</th>
                    <th>Shoes/Steals</th>
                  </tr>
              </thead>
              <tbody id="hittertable">
                
              </tbody>
            </table>
            <div className="button-div" style={{width: "100%"}}>
                <button id="decrement" className="amount-button me-2 btn btn-primary" disabled={disablePrev} onClick={async () => { decrement() }}>Prev</button>
                <p className="bg-dark" style={{width: "fit-content"}}>Page {tablePage}</p>
                <button id="increment" className="amount-button ms-2 btn btn-primary" disabled={disableNext} onClick={async () => { increment() }}>Next</button>
            </div>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            
            <table className="mt-4 table table-light">
              <thead>
                <tr className="table-dark"><th colSpan={8}><h2 className="tableHeader">Starting Pitcher Composites</h2></th></tr>
                  <tr style={{backgroundColor: "#B9B9B9"}}>
                    <th></th>
                    <th>Edition #</th>
                    <th>Name</th>
                    <th>Owner</th>
                    <th>Hat/Saves</th>
                    <th>Muscles/Strikeouts</th>
                    <th>Shoes/Innings Pitched</th>
                  </tr>
              </thead>
              <tbody id="compositetable">
                
              </tbody>
            </table>

            <div className="button-div" style={{width: "100%"}}>
                <button id="decrement" className="amount-button me-2 btn btn-primary" disabled={disablePrev} onClick={async () => { decrement() }}>Prev</button>
                <p className="bg-dark" style={{width: "fit-content"}}>Page {tablePage}</p>
                <button id="increment" className="amount-button ms-2 btn btn-primary" disabled={disableNext} onClick={async () => { increment() }}>Next</button>
            </div>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            
            <table className="mt-4 table table-light">
              <thead>
                <tr className="table-dark"><th colSpan={8}><h2 className="tableHeader">Reliever Pitcher Composites</h2></th></tr>
                  <tr style={{backgroundColor: "#B9B9B9"}}>
                    <th></th>
                    <th>Edition #</th>
                    <th>Name</th>
                    <th>Owner</th>
                    <th>Hat/Saves</th>
                    <th>Muscles/Strikeouts</th>
                    <th>Shoes/Innings Pitched</th>
                  </tr>
              </thead>
              <tbody id="relievertable">
                
              </tbody>
            </table>
            <div className="button-div mb-2" style={{width: "100%"}}>
                <button id="decrement" className="amount-button me-2 btn btn-primary" disabled={disablePrev} onClick={async () => { decrement() }}>Prev</button>
                <p className="bg-dark" style={{width: "fit-content"}}>Page {tablePage}</p>
                <button id="increment" className="amount-button ms-2 btn btn-primary" disabled={disableNext} onClick={async () => { increment() }}>Next</button>
            </div>
          </div>
        </div>
        <Modal show={showCompositeModal} onHide={closeAllModals} contentClassName=" bg-light" className={"mt-5 "}>
          <Modal.Header className="bg-dark">
            <Modal.Title style={{color:"rgb(0, 163, 41)"}}>{compositeObject.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {compositeModalData? (
              <div>
                <p><b>Status:</b> <b id="statusText">{compositeObject.status}</b></p>
                <div id="compositeContent"></div>
              </div>
            )
            : (
              <div>
                <p><b>Status:</b> <b id="statusText">{compositeObject.status}</b></p>
                <p>No Data Available</p>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
              <button className="btn btn-danger me-auto" onClick={async () => {await closeAllModals();}}>Close</button>
          </Modal.Footer>
        </Modal>
        <Modal show={showPlayerModal} onHide={closeAllModals} contentClassName="text-light bg-light compositeModal" className={""}>
          <Modal.Header className="bg-dark">
            <Modal.Title style={{color:"rgb(0, 163, 41)"}}>{playerObject.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive table-bordered">
              <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                <thead>
                  <tr><th className="" style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"5"}>Total Stats</th></tr>
                    {showPitcherStats? (
                      <tr className="table-secondary">
                        <th className="compositeCell">ERA</th>
                        <th className="compositeCell">Innings Pitched</th>
                        <th className="compositeCell">Saves</th>
                        <th className="compositeCell">Strike Outs</th>
                        <th className="compositeCell">Wins</th>
                      </tr>
                    )
                    : (
                      <tr className="table-secondary">
                        <th className="compositeCell">Batting Average</th>
                        <th className="compositeCell">RBI</th>
                        <th className="compositeCell">Runs</th>
                        <th className="compositeCell">Home Runs</th>
                        <th className="compositeCell">Stolen Bases</th>
                      </tr>
                    )}
                </thead>
                <tbody>
                  {showPitcherStats? (
                    <tr>
                      <td className="compositeCell">{playerObject.era}</td>
                      <td className="compositeCell">{playerObject.inningsPitched}</td>
                      <td className="compositeCell">{playerObject.saves}</td>
                      <td className="compositeCell">{playerObject.strikeOuts}</td>
                      <td className="compositeCell">{playerObject.wins}</td>
                    </tr>
                  )
                  : (
                    <tr>
                      <td className="compositeCell">{playerObject.ba}</td>
                      <td className="compositeCell">{playerObject.rbi}</td>
                      <td className="compositeCell">{playerObject.runs}</td>
                      <td className="compositeCell">{playerObject.homeRuns}</td>
                      <td className="compositeCell">{playerObject.stolenBases}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
        
            <div className="mt-4 table-responsive table-bordered modalTableOverflow">
                <table className="table table-hover table-light table-bordered" style={{marginBottom:"0px"}}>
                    <thead>
                    <tr><th style={{backgroundColor:"rgb(152, 174, 156)"}} colSpan={"7"}>Game Log</th></tr>
                        {showPitcherStats? (
                        <tr className="table-secondary">
                            <th className="compositeCell">Date</th>
                            <th className="compositeCell">Composite</th>
                            <th className="compositeCell">ERA</th>
                            <th className="compositeCell">IP</th>
                            <th className="compositeCell">Saves</th>
                            <th className="compositeCell">SO</th>
                            <th className="compositeCell">Wins</th>
                        </tr>
                        )
                        : (
                        <tr className="table-secondary">
                            <th className="compositeCell">Date</th>
                            <th className="compositeCell">Composite</th>
                            <th className="compositeCell">BA</th>
                            <th className="compositeCell">RBI</th>
                            <th className="compositeCell">Runs</th>
                            <th className="compositeCell">HR</th>
                            <th className="compositeCell">SB</th>
                        </tr>
                        )}
                    </thead>
                    <tbody id="gameLogBody">
                    </tbody>
                </table>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
              <button className="btn btn-danger me-auto" onClick={async () => {await closeAllModals();}}>Close</button>
          </Modal.Footer>
        </Modal>

        <div className="pagebottom"></div>
      </div>
    </div>
  );
};


export default Composites;