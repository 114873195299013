import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import * as bootstrap from "bootstrap/dist/js/bootstrap.bundle.min";
import Popper from "popper.js";

const qs = require("qs");
const env = process.env;


const Traits = () => {
    React.useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new bootstrap.Tooltip(tooltipTriggerEl)
        })
    },[])

  return (
    <div className="background-image-div">
      <div className="bg-div">
        <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
          <source src="/images/bg_final.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="div-background-content">
        <div className="div-content mx-auto mt-5" id="traits">
          <div className="table-responsive h-75 w-sm-50 w-100">
            <table className="table table-light">
              <thead className="table-secondary">
                <tr className="table-dark"><th colSpan={8}><h2 className="tableHeader">Hitter Traits</h2></th></tr>
                <tr>
                    <th data-html="true">Level</th>
                    <th>Bat/Batting Avg <span data-html="true" class="material-symbols-outlined" data-bs-toggle="tooltip" data-placement="top" title="# of Cards that have their first player at the noted level" data-bs-custom-class="tooltipbg">info</span></th>
                    <th>Muscles/Home Runs <span data-html="true" class="material-symbols-outlined" data-bs-toggle="tooltip" data-placement="top" title="# of Cards that have their second player at the noted level" data-bs-custom-class="tooltipbg">info</span></th>
                    <th>Shoes/Steals <span data-html="true" class="material-symbols-outlined" data-bs-toggle="tooltip" data-placement="top" title="# of Cards that have their third player at the noted level" data-bs-custom-class="tooltipbg">info</span></th>
                </tr>
              </thead>
              <tbody id="hittertable">
                <tr>
                    <td>5</td>
                    <td>182</td>
                    <td>200</td>
                    <td>193</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>307</td>
                    <td>322</td>
                    <td>305</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>1529</td>
                    <td>1477</td>
                    <td>1476</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>312</td>
                    <td>311</td>
                    <td>325</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>170</td>
                    <td>190</td>
                    <td>201</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="table-responsive h-75 w-sm-50 w-100">
            
            <table className="mt-4 table table-light">
              <thead>
                <tr className="table-dark"><th colSpan={8}><h2 className="tableHeader">Pitcher Traits</h2></th></tr>
                  <tr className="table-secondary">
                    <th>Level</th>
                    <th>Hat/Saves <span data-html="true" class="material-symbols-outlined" data-bs-toggle="tooltip" data-placement="top" title="# of Cards that have their first player at the noted level" data-bs-custom-class="tooltipbg">info</span></th>
                    <th>Muscles/Strikeouts <span data-html="true" class="material-symbols-outlined" data-bs-toggle="tooltip" data-placement="top" title="# of Cards that have their second player at the noted level" data-bs-custom-class="tooltipbg">info</span></th>
                    <th>Shoes/Innings Pitched <span data-html="true" class="material-symbols-outlined" data-bs-toggle="tooltip" data-placement="top" title="# of Cards that have their third player at the noted level" data-bs-custom-class="tooltipbg">info</span></th>
                    
                  </tr>
              </thead>
              <tbody id="teamtableroster">
                <tr>
                    <td>5</td>
                    <td>118</td>
                    <td>228</td>
                    <td>186</td>
                </tr>
                <tr>
                    <td>4</td>
                    <td>182</td>
                    <td>347</td>
                    <td>299</td>
                </tr>
                <tr>
                    <td>3</td>
                    <td>1392</td>
                    <td>1452</td>
                    <td>1507</td>
                </tr>
                <tr>
                    <td>2</td>
                    <td>706</td>
                    <td>305</td>
                    <td>300</td>
                </tr>
                <tr>
                    <td>1</td>
                    <td>102</td>
                    <td>168</td>
                    <td>208</td>
                </tr>
              </tbody>
            </table>

            
          </div>
        </div>

        <div className="pagebottom"></div>
      </div>
    </div>
  );
};

export default Traits;