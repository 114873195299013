import React from "react";
import ReactDOM from "react-dom";
//import { MoralisProvider, useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { useLocation, Navigate } from "react-router-dom";
import { BrowserRouter as Redirect } from "react-router-dom";
//import Moralis from "moralis";
import {isMobile, deviceDetect} from "react-device-detect";
import detectEthereumProvider from "@metamask/detect-provider";
import { useDispatch, useSelector } from "react-redux";
import { setSession } from "../redux/session/sessionSlice";
import { disconnect } from "../redux/blockchain/blockchainActions";
import * as functions from "./functions.js";
import { discover } from "dropzone";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer} from "react-toastify";
import axios from "axios";

const qs = require("qs");
const env = process.env;

const authenticated = functions.authenticated;
const fetchAsync = functions.fetchAsync;

const League = (props) => {
    // Moralis functions and properties.
    //const { authenticate, isAuthenticated, user, logout, enableWeb3 } = useMoralis();

    // Initialization of string state: This is the message that's displayed above the mint button.
    const [feedback, setFeedback] = React.useState(`Click buy to mint your NFT.`);

    const currentUser = useSelector((state) => state.session.currentUser)

    // Initialization of string state: This is the state that displays the username in the welcome message.
    const [username, setUsername] = React.useState();

    // Initialization of string state: This is a test state. Remove this for production versions.
    const [testfeedback, settestfeedback] = React.useState(`Test`);

    const [modalValue, setModalValue] = React.useState(0);

    const [modalName, setModalName] = React.useState("");

    const [showModal, setShowModal] = React.useState(false);

    const [redirect,setRedirect] = React.useState(false);

    const location = useLocation();

    const dispatch = useDispatch();
    
    async function logout() {
        dispatch(setSession(null));
        
        dispatch(disconnect());
        const logoutQuery = `${env.REACT_APP_BASEURLSERVICE}/auth.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}`

        const postLogoutObj = {
        type:5,
        
        sid: currentUser
        };

        await axios.post(logoutQuery, qs.stringify(postLogoutObj))
    }

    // Runs on pageload if user is authenticated by Moralis. Currently it just fills in the username state.
    React.useEffect(async () => {
        if(currentUser) {

            //console.log('location', location.pathname); // { key: "3uu089" }
            // Fire whatever function you need.

            // Initializes all the variables that go into the query
            const id = currentUser;

            const typeoneadd = `${env.REACT_APP_BASEURLSERVICE}/td_teamUpdate.php?apiKey=${env.REACT_APP_SERVICEAPIKEY}&type=1&u=${id}&c=${env.REACT_APP_COLLECTIONADD}`

            const leagueadd = `${env.REACT_APP_BASEURLSERVICE}/td_LeagueUpdate.php?type=1&apiKey=${env.REACT_APP_LEAGUEAPIKEY}`

            //console.log(typeoneadd)

            // Sends the fetch to SQL server, then sets the username it receives from it. 
            const c = await fetchAsync(typeoneadd).then(async (unameRes) => {
                let auth = authenticated(unameRes);

                if(!auth) {
                    logout();

                    toast.error("Unauthenticated session. Please log in again.");
                }
                else {
                    const user = unameRes.data[0];
                
                    const d = await fetchAsync(leagueadd).then(async (leagueRes) => {
                        const leagues = leagueRes.data;
    
                        let allowJoin = false;
    
                        if(user.leagueID == 0) {
                            allowJoin = true;
                        } else {
                            setRedirect(true);
                        }
    
                        for(let i = 0; i < leagues.length; i++) {
                            let league = leagues[i];
    
                            if(document.getElementById("leagueDiv")) {
                                let table = document.getElementById("leagueDiv");
    
                                let text1 = `League ${league.leagueID}: ${league.leagueName} League`;
    
                                let text2 = `Participating Teams: ${league.numberTeams}`;
    
                                let text3 = `Join ${league.leagueName} League`;
    
                                let div = document.createElement("div");
    
                                let h1 = document.createElement("h3");
    
                                let h2 = document.createElement("h4");
    
                                let button = document.createElement("button");
    
                                h1.textContent = text1;
    
                                h1.className = ""
    
                                h2.textContent = text2;
    
                                h2.className = "mt-2"
    
                                button.textContent = text3;
    
                                button.className = "btn btn-primary mt-5";
    
                                button.disabled = (!allowJoin);
    
                                button.onclick = await handleShow.bind(null,league.leagueID, league.leagueName);
    
                                div.className = "p-3 text-center col-sm-6 col-12"
    
                                div.appendChild(h1);
    
                                div.appendChild(h2);
    
                                div.appendChild(button);
    
                                table.appendChild(div);
                            }
                        }
                    })
                }
                
            })
            
        }
    }, [currentUser]);

    async function handleShow(val, name) {
        setModalValue(val);

        setModalName(name);

        setShowModal(true);
    }
    async function handleClose() {
        setModalValue(0);

        setShowModal(false);
    }

    async function joinLeague(id) {
        const session = currentUser

        const leagueJoin = `${env.REACT_APP_BASEURLSERVICE}/td_LeagueUpdate.php?type=3&apiKey=${env.REACT_APP_LEAGUEAPIKEY}&c=${env.REACT_APP_COLLECTIONADD}&u=${session}&l=${id}`;

        const a = await fetch(leagueJoin).then(res => res.json()).then(async leagueRes => {
            console.log(leagueRes);

            setRedirect(true);
        })
    }

    if (!currentUser) {
        return (
            <div id="pagetop" className="background-image-div">
                <div className="bg-div">
                    <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                        <source src="/images/bg_final.mp4" type="video/mp4" />
                    </video>
                </div>

                <div className="d-flex justify-content-center div-background-content">
                    <div className="align-self-center div-content">
                        <h2>Log in to join a league!</h2>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="pagetop" className="background-image-div d-inline-flex flex-column">
            <div className="bg-div">
                <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                    <source src="/images/bg_final.mp4" type="video/mp4" />
                </video>
            </div>

            <div className="div-background-content">
                {redirect && (
                    <Navigate to="/roster" replace={true} />
                )}
                <div className="div-content bg-home mx-auto mt-5 p-5">
                    
                    <div id="" className="container text-center">
                        <h1>Leagues</h1>
                        <h2>Caution! Once you select a league, you can't change your team's league!</h2>
                        <div className="row" id="leagueDiv">

                        </div>
                        <Modal show={showModal} contentClassName="text-light bg-dark" className={"mt-5 "}>
                            <Modal.Header>
                                <Modal.Title>Join {modalName} League</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to join this League?
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary me-auto" onClick={async () => {await handleClose();}}>No</button>

                                <button className="btn btn-success" onClick={async () => {await joinLeague(modalValue)}}>Yes</button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default League;