import React from "react";
import ReactDOM from "react-dom";
import { HashLink as Link } from "react-router-hash-link";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Modal } from "react-bootstrap";
//import { useMoralis } from "react-moralis";

const Controls = ({ zoomIn, zoomOut, resetTransform }) => (
    <>
      <button onClick={() => zoomIn()}>+</button>
      <button onClick={() => zoomOut()}>-</button>
      <button onClick={() => resetTransform()}>x</button>
    </>
  );
const Beta = () => {
  //const { authenticate, isAuthenticated, user, logout } = useMoralis();
  /*
  if (!isAuthenticated) {
    return (
      <div id="pagetop" className="background-image-div">
        <div className="bg-div">
            <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
                <source src="/images/bg.mp4" type="video/mp4" />
            </video>
        </div>
        
        <div className="div-background-content">
            <div className="align-self-center mx-auto div-content">
                <p></p>
                
            </div>
        </div>
      </div>
    );
  }*/

  const [showGuideModal,setShowGuideModal] = React.useState(false);

  const [nextInactive,setNextInactive] = React.useState(false);

  const [prevInactive,setPrevInactive] = React.useState(false);

  const [guideObject, setGuideObject] = React.useState([{num: 1, text: "", img: "none.png", extra: false}]);

  const [currentStep,setCurrentStep] = React.useState(0);

  const [maxSteps,setMaxSteps] = React.useState(1);

  const base = [
    {num: 1, text: "", img: "1.png", extra: false},
    {num: 2, text: "", img: "2.png", extra: false},
    {num: 3, text: "", img: "3.png", extra: false},
    {num: 4, text: "", img: "4.png", extra: false},
    {num: 5, text: "", img: "5.png", extra: false},
    {num: 6, text: "", img: "6.png", extra: false},
    {num: 7, text: "", img: "7.png", extra: false},
    {num: 8, text: "", img: "8.png", extra: false},
    {num: 9, text: "", img: "9.png", extra: false},
    {num: 10, text: "", img: "10.png", extra: false},
    {num: 11, text: "", img: "11.png", extra: false},
    {num: 12, text: "", img: "12.png", extra: false},
    {num: 13, text: "", img: "13.png", extra: false},
    {num: 14, text: "", img: "14.png", extra: false},
    {num: 15, text: "", img: "15.png", extra: false},
    {num: 16, text: "", img: "16.png", extra: false},
    {num: 17, text: "", img: "17.png", extra: false},
    {num: 18, text: "", img: "18.png", extra: false},
    {num: 19, text: "", img: "19.png", extra: false},
    {num: 20, text: "", img: "20.png", extra: false},
    {num: 21, text: "", img: "21.png", extra: false},
    {num: 22, text: "", img: "22.png", extra: false},
    {num: 23, text: "", img: "23.png", extra: false},
    {num: 24, text: "", img: "24.png", extra: false},
    {num: 25, text: "", img: "25.png", extra: false},
    {num: 26, text: "", img: "26.png", extra: false}
  ]

  const metamaskD = [
    {num: 1, text: "Click the link to go to the Metamask Download page", img: "d1.png", extra: false},
    {num: 2, text: "Select which browser you want to install the browser plugin. This will be the name of the browser you are using.", img: "d2.png", extra: false},
    {num: 3, text: "Click the ‘Install Metamask’ button", img: "d3.png", extra: false},
    {num: 4, text: "Click 'Add to Chrome'", img: "d4.png", extra: false},
    {num: 5, text: "On the popup, click ‘Add Extension’", img: "d5.png", extra: false},
    {num: 6, text: "Select 'Create a New Wallet'", img: "d6.png", extra: false},
    {num: 7, text: "If you would like to share anonymous data with Metamask, click the ‘I Agree’ button, other click ‘No Thanks’. Your choice here does not affect your ability to use the Metamask Web3 Wallet.", img: "d7.png", extra: false},
    {num: 8, text: "Create a password for your wallet. Choose a strong password that you can remember. The plugin will indicate the strength of the password you have chosen.", img: "d8.png", extra: false},
    {num: 9, text: "The password you choose is stored on your computer, not by Metamask and not by Ethball. IF YOU FORGET THE PASSWORD IT CANNOT BE RECOVERED BY METAMASK OR ETHBALL!!! By checking the box you are agreeing that you understand the password cannot be recovered by anyone. Finally, click ‘Create a new wallet’.", img: "d9.png", extra: false},
    {num: 10, text: "Watch the ‘Secure your wallet’ video. Really, watch the video!", img: "d10.png", extra: false},
    {num: 11, text: "Click ‘Secure my wallet (recommended)’ to start the process of securing your Web3 Wallet.", img: "d11.png", extra: false},
    {num: 12, text: "After watching the video you now realize how important your Secret Recovery Phrase is. Write it down and store it in a safe and secure place. Please note: the exact spelling of the words AND the order of the words matter, so make sure you meticulously write down the exact words and order! If you ever forget your password to your wallet, this recovery phrase will be necessary to get access to your account again. After writing it down, click the ‘Next’ button.", img: "d12.png", extra: false},
    {num: 13, text: "The Metamask extension will now quiz you to make sure you have things written down accurately. Type in the missing words in the appropriate fields and click the ‘Confirm’ button.", img: "d13.png", extra: false},
    {num: 14, text: "If you have entered the information correctly you should see a screen that says ‘Wallet creation successful’. Read the message on the screen and click ‘Got it!’.", img: "d14.png", extra: false},
    {num: 15, text: "For quick access to the Metamask Web3 browser extension, pin it to the browser toolbar. Start by clicking the browser extension icon in the top right corner of the browser.", img: "d15.png", extra: false},
    {num: 16, text: "In the dropdown, click the pin icon to the right of the Metamask extension.", img: "d16.png", extra: false},
    {num: 17, text: "Click 'Next'", img: "d17.png", extra: false},
    {num: 18, text: "With the Metamask browser extension pinned, click the ‘Done’ button.", img: "d18.png", extra: false},
    {num: 19, text: "The final step is to switch your wallet from the Ethereum Mainnet to the Goerli test network we are using for the Ethball Beta Test. To switch over to the Goerli test network, click the drop down menu in the upper right hand corner.", img: "d19.png", extra: false},
    {num: 20, text: "In the drop down menu, click the link that says, ‘Show/hide test networks’", img: "d20.png", extra: false},
    {num: 21, text: "This should open the Settings page to the ‘Show test networks’ switch. If you do not see it immediately, you may need to scroll up or down in the menu.", img: "d21.png", extra: false},
    {num: 22, text: "Click on the switch to flip it to the ‘On’ position.", img: "d22.png", extra: false},
    {num: 23, text: "Scroll to the top of the menu and click the ‘X’ in the upper right hand corner of the menu.", img: "d23.png", extra: false},
    {num: 24, text: "Select the drop down menu in the upper right hand corner of the page that says ‘Ethereum Mainnet’.", img: "d24.png", extra: false},
    {num: 25, text: "In the menu you should now see the Ethereum Mainnet option plus at least 2 testnet options, one of which should be the Goerli Testnet. Click on ‘Goerli test network’.", img: "d25.png", extra: false},
    {num: 26, text: "The drop down menu should now display the Goerli test network by default. Congratulations, you are now ready to login to the Ethball website!", img: "d26.png", extra: false}
  ]
  const metamaskI = [
    {num: 1, text: (<p>Open the mobile browser on your iPhone and go to <a href="https://ethball.io/beta" target={"_blank"}>https://ethball.io/beta</a>. Click on the Metamask link in the section titled ‘Install a Web3 Wallet Browser Extension'. This will open the Metamask app download page in the app store.</p>), img: "im1.png", extra: false, mobile: true},
    {num: 2, text: "Select iOS at the top of the page, and then tap on the ‘Install Metamask for iPhone’ button at the bottom of the page.", img: "im2.png", extra: false, mobile: true},
    {num: 3, text: "To install the Metamask iOS app, tap the ‘Get’ button.", img: "im3.png", extra: false, mobile: true},
    {num: 4, text: "Install should begin.", img: "im4.png", extra: false, mobile: true},
    {num: 5, text: "Once the install has completed, tap the ‘Open’ button.", img: "im5.png", extra: false, mobile: true},
    {num: 6, text: "After the Metamask app opens, tap the ‘Get Started’ button at the bottom of the page.", img: "im6.png", extra: false, mobile: true},
    {num: 7, text: "Tap the ‘Create a new wallet’ button at the bottom of the page.", img: "im7.png", extra: false, mobile: true},
    {num: 8, text: "Type in a good password that you can remember in the password boxes.", img: "im8.png", extra: false, mobile: true},
    {num: 9, text: "The app will indicate the strength of the password you have chosen.", img: "im9.png", extra: false, mobile: true},
    {num: 10, text: "Check the box that you understand that Metamask cannot recover your password for you if you should forget it. BE ABSOLUTELY SURE TO STORE YOUR PASSWORD SOMEWHERE SAFE AND SECURE! Then click the ‘Create Password’ button to continue.", img: "im10.png", extra: false, mobile: true},
    {num: 11, text: "We highly recommend you watch the ‘Secure your wallet’ video, it is only a minute and a half long. After you have watched the video, click the ‘Start’ button.", img: "im11.png", extra: false, mobile: true},
    {num: 12, text: "Read the tips about storing your Secret Recovery Phrase (the Secret Recovery Phrase will be the only way to recover your wallet if you do happen to forget your password!).", img: "im12.png", extra: false, mobile: true},
    {num: 13, text: "Click the ‘Start’ button to continue.", img: "im13.png", extra: false, mobile: true},
    {num: 14, text: "Tap ‘View’ to see your Secret Recovery Phrase.", img: "im14.png", extra: false, mobile: true},
    {num: 15, text: "Store the 12 words exactly as they are spelled and in the same order they are shown. THE EXACT SPELLING AND ORDER OF THE WORDS MATTER! Once you have written them down, click the ‘Continue’ button. (I have intentionally blurred out some in this photo).", img: "im15.png", extra: false, mobile: true},
    {num: 16, text: "To prove to Metamask that you have written them down in the correct order, tap the Secret Recovery Phrase at the bottom of the page in order.", img: "im16.png", extra: false, mobile: true},
    {num: 17, text: "Here you can see I have partially completed the task.", img: "im17.png", extra: false, mobile: true},
    {num: 18, text: "If you have submitted the Secret Recovery Phrase in the correct order, the ‘Complete Backup’ button can be clicked.", img: "im18.png", extra: false, mobile: true},
    {num: 19, text: "Success! You should see confetti!!", img: "im19.png", extra: false, mobile: true},
    {num: 20, text: "After the party is over, tap the ‘Done’ button to continue. This screen also has an option that allows you to leave yourself a hint as to where you have securely stored your Secret Recovery Phrase. If you would like to leave a hint, do that before you tap the ‘Done’ button.", img: "im20.png", extra: false, mobile: true},
    {num: 21, text: "We recommend you tap the ‘Enable automatic security checks’ to ensure your Metamask wallet is as secure as possible.", img: "im21.png", extra: false, mobile: true},
    {num: 22, text: "You should now see the Metamask wallet main page. The first time you see this page you will be given an option to have a short guided tour of the app. We recommend you click the ‘Take the tour’ button if this is your first time using the Metamask app. Otherwise you can click the ‘No, thanks’ button.", img: "im22.png", extra: false, mobile: true},
    {num: 23, text: "Back at the main page of the Metamask wallet, tap to open the drop down menu at the top of the page to select the Goerli testnet.", img: "im23.png", extra: false, mobile: true},
    {num: 24, text: "In the drop down menu, tap to select the Goerli Test Network.", img: "im24.png", extra: false, mobile: true},
    {num: 25, text: "Read through the points and tap the ‘Got it’ button.", img: "im25.png", extra: false, mobile: true},
    {num: 26, text: "Now the main page of the Metamask app should indicate that you are set to connect to the Ethball website using the Goerli Test Network.", img: "im26.png", extra: false, mobile: true},
    {num: 27, text: "This is the Metamask launch icon. Whenever you want to visit the ethball.io website, tap this icon to open the Metamask app.", img: "im27.png", extra: false, mobile: true},
    {num: 28, text: "At the bottom of the Metamask app page is the ‘Browser’ button. Tap that button to use the browser built-in to the Metamask app. ON MOBILE DEVICES, ONLY THE METAMASK BUILT-IN BROWSER WILL ALLOW YOU TO LOG IN TO YOUR TEAM WITH YOUR METAMASK WALLET.", img: "im28.png", extra: false, mobile: true},
    {num: 29, text: "Type ‘ethball.io’ into the browser address bar at the top and you will arrive at the Ethball home page. Congratulations, your Metamask wallet is now setup and ready to go! You are now ready to continue to the next guide - Join the Discord Server.", img: "im29.png", extra: false, mobile: true}
  ]
  const discord = [
    {num: 1, text: "Click the invite link to join the Ethball Discord Server", img: "ds1.png", extra: false},
    {num: 2, text: (<p>If you are not currently logged in to Discord, you will see a screen that looks like this. Click on ‘Already have an account?’. If you are already logged in, you can <a href="#" onClick={async () => {await skipToStep(7)}}>skip to step 7</a>. If you have not created a Discord account yet, click the link <a className="" href="https://support.discord.com/hc/en-us/articles/360033931551-Getting-Started" role="button" target={"_blank"}>here</a> for a guide to create a free Discord Account.</p>), img: "ds2.png", extra: false},
    {num: 3, text: "Type in the email address and password you used to create your Discord Account.", img: "ds3.png", extra: false},
    {num: 4, text: "Click ‘Log In’.", img: "ds4.png", extra: false},
    {num: 5, text: (<p>If you have Two-factor authentication enabled, open your 2fa app and enter the code you see in that app. If you do not have 2fa enabled, <a href="#" onClick={async () => {await skipToStep(7)}}>skip to step 7</a>. As a side note, we HIGHLY recommend you use 2fa as an additional layer of security. <a href="https://support.discord.com/hc/en-us/articles/219576828-Setting-up-Two-Factor-Authentication" target={"_blank"}>Here is a guide</a> Discord created on how to set that up if you are interested.</p>), img: "ds5.png", extra: false},
    {num: 6, text: "After typing in your 2fa code, click ‘Log In’.", img: "ds6.png", extra: false},
    {num: 7, text: "Click ‘Accept Invite’ to join the Ethball Discord Server.", img: "ds7.png", extra: false},
    {num: 8, text: "Please read through rules of the Ethball Discord Server and if you agree to the rules, click the ‘Verify’ button.", img: "ds8.png", extra: false},
    {num: 9, text: "If you are successful you should see all of the channels in the Ethball Discord Server appear in the left hand ‘Channels’ column.", img: "ds9.png", extra: false},
  ]
  const link = [
    {num: 1, text: "On the ethball.io website, click the wallet icon in the upper right hand corner of the website.", img: "ld1.png", extra: false},
    {num: 2, text: "On the pop-up window, click on ‘Metamask’.", img: "ld2.png", extra: false},
    {num: 3, text: (<p>The Metamask Web3 Wallet extension should open. Your default account should already be selected. Click ‘Next’ to connect your default account to Ethball.io. (If the Metamask extension menu does not open automatically, look in the upper right hand corner of your browser for the Metamask icon with the notification mark <img src="https://ebimg.ethball.io/demoimg/metamasknotification.png" /> and click that.) If you have logged in to the Ethball.io website with your Metamask wallet previously, you will not see this screen so you can <a href="#" onClick={async () => {await skipToStep(5)}}>jump to step 5</a>.</p>), img: "ld3.png", extra: false},
    {num: 4, text: "Read the Connection privileges you are agreeing to and hit ‘Connect’.", img: "ld4.png", extra: false},
    {num: 5, text: "By signing this message, you are agreeing to the Ethball Terms of Service, agreeing that you know and understand how the game works, and that you want to log in to the site. Click ‘Sign’ to accept the terms and log in.", img: "ld5.png", extra: false},
    {num: 6, text: "You will know that you have successfully logged in as the wallet icon will be replaced with your profile icon (or the default profile icon).", img: "ld6.png", extra: false},
    {num: 7, text: "Click the profile icon and select ‘Settings’.", img: "ld7.png", extra: false},
    {num: 8, text: "Click ‘Link to Discord’.", img: "ld8.png", extra: false},
    {num: 9, text: "Linking your Discord account will log you out of the Ethball site to complete the connection. Click ‘Yes’ if you want to link your Discord account to your Ethball account right now.", img: "ld9.png", extra: false},
    {num: 10, text: (<p>If you are not currently logged in to Discord, you will see a screen that looks like this. Click on ‘Already have an account?’. If you are already logged in, you can <a href="#" onClick={async () => {await skipToStep(15)}}>skip to step 15</a>. If you have not created a Discord account yet, click the link <a className="" href="https://support.discord.com/hc/en-us/articles/360033931551-Getting-Started" role="button" target={"_blank"}>here</a> for a guide to create a free Discord Account.</p>), img: "ld10.png", extra: false},
    {num: 11, text: "Type in the email address and password you used to create your Discord Account.", img: "ld11.png", extra: false},
    {num: 12, text: "Click ‘Log In’.", img: "ld12.png", extra: false},
    {num: 13, text: (<p>If you have Two-factor authentication enabled, open your 2fa app and enter the code you see in that app. If you do not have 2fa enabled, <a href="#" onClick={async () => {await skipToStep(15)}}>skip to step 15</a>. As a side note, we HIGHLY recommend you use 2fa as an additional layer of security. <a href="https://support.discord.com/hc/en-us/articles/219576828-Setting-up-Two-Factor-Authentication" target={"_blank"}>Here is a guide</a> Discord created on how to set that up if you are interested.</p>), img: "ld13.png", extra: false},
    {num: 14, text: "After typing in your 2fa code, click ‘Log In’.", img: "ld14.png", extra: false},
    {num: 15, text: "Click ‘Authorize’ to allow Ethball to link to your Discord account.", img: "ld15.png", extra: false},
    {num: 16, text: "After successfully linking your Discord account to your Ethball account, you will be redirected back to the Ethball website. Click the wallet icon to login to the Ethball website.", img: "ld16.png", extra: false},
    {num: 17, text: "On the pop-up, click ‘Metamask’", img: "ld17.png", extra: false},
    {num: 18, text: "Agree to the terms again and click ‘Sign’ to login.", img: "ld18.png", extra: false},
    {num: 19, text: "Click the profile icon.", img: "ld19.png", extra: false},
    {num: 20, text: "Click ‘Settings’.", img: "ld20.png", extra: false},
    {num: 21, text: "And if the link from your Discord account to your Ethball account is successful, you should receive this message that your Discord account is linked.", img: "ld21.png", extra: false},
  ]
  const goerli = [
    {num: 1, text: "In the Ethball Discord Server, click on the ‘help-tickets’ channel", img: "te1.png", extra: false},
    {num: 2, text: "In the ‘help-tickets’ channel, click on ‘Create ticket’", img: "te2.png", extra: false},
    {num: 3, text: "A new ticket should appear under the ‘HELP TICKETS’ header in the channel column on the left. Click on the ticket.", img: "te3.png", extra: false},
    {num: 4, text: "Type a short message to request some testnet GoerliEth. Hit Enter to submit.", img: "te4.png", extra: false},
    {num: 5, text: "Someone from the Ethball team will type a response once some GoerliEth has been sent to you. If we have any questions they will be posted in the Help Ticket as well.", img: "te5.png", extra: false},
    {num: 6, text: "Once you receive the message that we have sent the GoerliEth, you can close the Help Ticket by clicking the ‘Close’ button.", img: "te6.png", extra: false},
    {num: 7, text: "Verify that you want to close the ticket by clicking the red ‘Close’ button.", img: "te7.png", extra: false},
    {num: 8, text: "To verify that you have received your test GoerliEth, click on the Metamask Web3 Browser extension icon in your browser.", img: "te8.png", extra: false},
    {num: 9, text: "Once the Metamask extension menu opens you should see that you now have a balance of 0.5 GoerliEth. If you do not see a balance, make sure you have ‘Goerli test network’ selected. If it is not selected, click the drop down menu at the top of the extension and select ‘Goerli test network’. If you do not see the ‘Goerli test network’ option in your list, check our guide called ‘Guide to install Web3 Wallet Extension on Mac or PC' for instructions on how to show test networks.", img: "te9.png", extra: false, mobile: true},
  ]
  const packs = [
    {num: 1, text: "On ethball.io click the wallet icon to login.", img: "bp1.png", extra: false},
    {num: 2, text: "On the pop-up select the Metamask Web3 Wallet.", img: "bp2.png", extra: false},
    {num: 3, text: "Agree to the ethball.io terms and click ‘Sign’ to login.", img: "bp3.png", extra: false,mobile:true},
    {num: 4, text: "On the top navigation menu, click ‘Join’.", img: "bp4.png", extra: false,mobile:true},
    {num: 5, text: "You can buy up to 5 packs. Click on the + button to add more packs to purchase and - to subtract packs. Once you have the number of packs you would like to buy, click the ‘Buy’ button.", img: "bp5.png", extra: false},
    {num: 6, text: "This will cause the Metamask Web3 Wallet extension to open. There are several fields worth noting:", img: "bp6.png", extra: (<ol className="listHighlight">
        <li>This is the total of all of the packs you have selected multiplied by the price per pack. So if you selected 2 packs and the purchase price is .025 GoerliEth per pack, you would get the total of 0.05 as shown in the image.</li>
        <li>Gas is the name used by the Ethereum blockchain to indicate the cost to make this transaction. Just think of this as a transaction fee to buy the packs.</li>
        <li>The total is the price of the packs + the Gas (or transaction fee).</li>
        <li>If you want to make this purchase, click the ‘Confirm’ button and your transaction will be processed. If you change your mind you can press the ‘Reject’ button instead.</li>
    </ol>),mobile:true},
    {num: 7, text: "If you clicked the ‘Confirm’ button you will see a page like this. At this point just wait, the page will automatically update once the transaction completes. In the event it fails you will also receive a message here.", img: "bp7.png", extra: false},
    {num: 8, text: "The transaction succeeded!", img: "bp8.png", extra: false},
    {num: 9, text: "To see your new players and the composites for each player, click on the ‘Manage Team’ menu. Sometimes the Blockchain is running slowly so you may need to be patient. Another option is to log out and log back in a few minutes later.", img: "bp9.png", extra: false},
    {num: 10, text: "Select the ‘Manage Roster’ option.", img: "bp10.png", extra: false},
    {num: 11, text: (<div>If this is the first time you are looking at your roster you will first need to join a League. For the beta season there is only one league option - click on the ‘Join Terran League’ button. If you are already in a league you can <a href="#" onClick={async () => {await skipToStep("Packs",13)}}>skip to step 13</a>.</div>), img: "bp11.png", extra: false},
    {num: 12, text: "Confirm your league selection by clicking the ‘Yes’ button.", img: "bp12.png", extra: false},
    {num: 13, text: "Here you can view your roster and the composite players that make up the DNA of your players. You can also see which composite is active on the current day (in bold), which are active tomorrow (in italics), and you can active or deactivate players for the next day with the switch to the far right of each player row.", img: "bp13.png", extra: false},
  ]

  async function renderStep() {
    let img = document.getElementById("stepimg");
    img.classList.remove("step-img");
    img.classList.remove("step-img-mobile");
    if(guideObject[currentStep].mobile) {
        img.classList.add("step-img-mobile");
    } else {
        img.classList.add("step-img");
    }
    let val = currentStep + 1;

    console.log(val);

    if(val == 1) {
        setPrevInactive(true);
    } else {
        setPrevInactive(false);
    }

    if(val >= maxSteps) {
        setNextInactive(true);
    } else {
        setNextInactive(false);
    }
  }

  async function previousStep() {
    const val = currentStep;

    const newVal = val - 1;

    setCurrentStep(newVal);
  }

  async function nextStep() {
    const val = currentStep;

    const newVal = val + 1;

    setCurrentStep(newVal);
  }

  async function skipToStep(step) {
    setCurrentStep(step - 1);

    renderStep();
  }

  async function closeAllModals() {
    setShowGuideModal(false);
  }

  async function openGuideModal(obj) {
    setGuideObject(obj);

    setMaxSteps(obj.length);

    setShowGuideModal(true);
  }

  React.useEffect(async () => {
    if(showGuideModal) {
        skipToStep(1);
    }
    
  }, [showGuideModal]);
  React.useEffect(async () => {
    if(showGuideModal) {
        renderStep();
    }
    
  }, [currentStep]);
  
  return (
    <div id="pagetop" className="background-image-div">
        <div className="bg-div">
          <video id="video" className="bg-video" playsInline={true} autoPlay={true} loop={true} muted={true}>
            <source src="/images/bg_final.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="div-background-content">

            <div className="mx-auto div-content bg-home px-5 py-3">
                <h1>Beta Test Startup Info</h1>
                <p>
                    Thanks to everyone who is helping us to test the game this season! Your help and feedback will help us guide the decisions we need to make to get the game complete and fun for everyone!
                </p>
                <p><b className="highlightText" >Please note:</b> The following guides contain images that are zoomed in on the relevant location for that instruction. If you'd like to see the entire image for that instruction, click the 'Zoom Out' button. If you would like to return to the zoomed in position of the image, click 'Reset'.</p>
                <p>To get started, everyone involved will need to do the following:</p>
                <ol>
                    <li>Install a Web3 Wallet Browser Extension. We recommend <a href="https://metamask.io/download/" target={"_blank"}>Metamask!</a>
                        <ul>
                            <li><a className="" data-bs-toggle="collapse" href="#collapseBrowsers" role="button" aria-expanded="false" aria-controls="collapseBrowsers">Supported Browsers</a></li>
                            <div className="collapse" id="collapseBrowsers">
                                <div className="card card-body bg-dark">
                                    <li>Supported Browsers:
                                        <ul>
                                            <li>Chrome</li>
                                            <li>Opera</li>
                                            <li>Brave</li>
                                            <li>Firefox</li>
                                            <li>Edge</li>
                                        </ul>
                                    </li>
                                </div>
                            </div>
                            <li><a className="" href="#" onClick={async (a) => {a.preventDefault(); openGuideModal(metamaskD);}}>Guide to install Web3 Wallet Extension on Mac or PC</a></li>
                            
                            <li><a className="" href="#" onClick={async (a) => {a.preventDefault(); openGuideModal(metamaskI);}}>Guide to install Web3 browser app for iPhone</a></li>
                            <div className="collapse" id="collapseMetamaskI">
                                <div className="card card-body bg-dark">
                                    <div id="MetamaskI" data-value={1}>
                                        <div>
                                            <div className="step stepMetamaskI" data-value={1}>
                                                <p>1. Open the mobile browser on your iPhone and go to <a href="https://ethball.io/beta" target={"_blank"}>https://ethball.io/beta</a>. Click on the Metamask link in the section titled ‘Install a Web3 Wallet Browser Extension'. This will open the Metamask app download page in the app store.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im1.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={2}>
                                                <p>2. Select iOS at the top of the page, and then tap on the ‘Install Metamask for iPhone’ button at the bottom of the page.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im2.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={3}>
                                                <p>3. To install the Metamask iOS app, tap the ‘Get’ button.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im3.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={4}>
                                                <p>4. Install should begin.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im4.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={5}>
                                                <p>5. Once the install has completed, tap the ‘Open’ button.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im5.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={6}>
                                                <p>6. After the Metamask app opens, tap the ‘Get Started’ button at the bottom of the page.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im6.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={7}>
                                                <p>7. Tap the ‘Create a new wallet’ button at the bottom of the page.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im7.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={8}>
                                                <p>8. Type in a good password that you can remember in the password boxes.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im8.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={9}>
                                                <p>9. The app will indicate the strength of the password you have chosen.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im9.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={10}>
                                                <p>10. Check the box that you understand that Metamask cannot recover your password for you if you should forget it. BE ABSOLUTELY SURE TO STORE YOUR PASSWORD SOMEWHERE SAFE AND SECURE! Then click the ‘Create Password’ button to continue.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im10.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={11}>
                                                <p>11. We highly recommend you watch the ‘Secure your wallet’ video, it is only a minute and a half long. After you have watched the video, click the ‘Start’ button.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im11.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>

                                            <div className="step stepMetamaskI" data-value={12}>
                                                <p>12. Read the tips about storing your Secret Recovery Phrase (the Secret Recovery Phrase will be the only way to recover your wallet if you do happen to forget your password!).</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im12.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={13}>
                                                <p>13. Click the ‘Start’ button to continue.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im13.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={14}>
                                                <p>14. Tap ‘View’ to see your Secret Recovery Phrase.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im14.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={15}>
                                                <p>15. Store the 12 words exactly as they are spelled and in the same order they are shown. THE EXACT SPELLING AND ORDER OF THE WORDS MATTER! Once you have written them down, click the ‘Continue’ button. (I have intentionally blurred out some in this photo).</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im15.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={16}>
                                                <p>16. To prove to Metamask that you have written them down in the correct order, tap the Secret Recovery Phrase at the bottom of the page in order.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im16.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={17}>
                                                <p>17. Here you can see I have partially completed the task.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im17.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={18}>
                                                <p>18. If you have submitted the Secret Recovery Phrase in the correct order, the ‘Complete Backup’ button can be clicked.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im18.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={19}>
                                                <p>19. Success! You should see confetti!!</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im19.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={20}>
                                                <p>20. After the party is over, tap the ‘Done’ button to continue. This screen also has an option that allows you to leave yourself a hint as to where you have securely stored your Secret Recovery Phrase. If you would like to leave a hint, do that before you tap the ‘Done’ button.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im20.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={21}>
                                                <p>21. We recommend you tap the ‘Enable automatic security checks’ to ensure your Metamask wallet is as secure as possible.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im21.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={22}>
                                                <p>22. You should now see the Metamask wallet main page. The first time you see this page you will be given an option to have a short guided tour of the app. We recommend you click the ‘Take the tour’ button if this is your first time using the Metamask app. Otherwise you can click the ‘No, thanks’ button.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im22.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={23}>
                                                <p>23. Back at the main page of the Metamask wallet, tap to open the drop down menu at the top of the page to select the Goerli testnet.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im23.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={24}>
                                                <p>24. In the drop down menu, tap to select the Goerli Test Network.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im24.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={25}>
                                                <p>25. Read through the points and tap the ‘Got it’ button.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im25.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={26}>
                                                <p>26. Now the main page of the Metamask app should indicate that you are set to connect to the Ethball website using the Goerli Test Network.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im26.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={27}>
                                                <p>27. This is the Metamask launch icon. Whenever you want to visit the ethball.io website, tap this icon to open the Metamask app.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im27.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={28}>
                                                <p>28. At the bottom of the Metamask app page is the ‘Browser’ button. Tap that button to use the browser built-in to the Metamask app. ON MOBILE DEVICES, ONLY THE METAMASK BUILT-IN BROWSER WILL ALLOW YOU TO LOG IN TO YOUR TEAM WITH YOUR METAMASK WALLET.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im28.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            
                                            <div className="step stepMetamaskI" data-value={29}>
                                                <p>29. Type ‘ethball.io’ into the browser address bar at the top and you will arrive at the Ethball home page. Congratulations, your Metamask wallet is now setup and ready to go! You are now ready to continue to the next guide - Join the Discord Server.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/im29.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                        </div>
                                        <div className="mt-3 d-flex justify-content-around">
                                            <button className="btn btn-primary" id="prevMetamaskI" onClick={async () => {await previousStep("MetamaskI")}}>Previous Step</button>
                                            <p style={{width: "fit-content"}} className="text-center" id="numMetamaskI"></p>
                                            <button className="btn btn-primary" id="nextMetamaskI" onClick={async () => {await nextStep("MetamaskI")}}>Next Step</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <li><a className="" data-bs-toggle="collapse" href="#collapseMetamaskA" role="button" aria-expanded="false" aria-controls="collapseMetamaskA">Guide to install Web3 browser app for Android</a></li>
                            <div className="collapse" id="collapseMetamaskA">
                                <div className="card card-body bg-dark">
                                    <p>Coming Soon</p>
                                </div>
                            </div>

                        </ul>
                    </li>
                    
                    
                    
                    <li>Join the <a href="https://discord.gg/rusc2JzmEh" target={"_blank"}>Discord Server</a>. If you don't have a Discord Account, follow this <a className="" href="https://support.discord.com/hc/en-us/articles/360033931551-Getting-Started" role="button" target={"_blank"}>guide</a> to create a free Discord Account!
                        <ul>
                            <li><a className="" href="#" onClick={async (a) => {a.preventDefault(); openGuideModal(discord);}}>Guide to join Discord Server</a> (note: membership in the Ethball Discord Server requires you to register your phone number with Discord. Discord does not disclose your phone number to the Ethball Team or anyone else. This is a security measure to help filter out bots.)</li>
                            <div className="collapse" id="collapseDiscord">
                                <div className="card card-body bg-dark">
                                    <div id="Discord" data-value={1}>
                                        <div>
                                            <div className="step stepDiscord" data-value={1}>
                                                <p>1. Click the invite link to join the Ethball Discord Server</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds1.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepDiscord" data-value={2}>
                                                <p>2. If you are not currently logged in to Discord, you will see a screen that looks like this. Click on ‘Already have an account?’. If you are already logged in, you can <a href="#" onClick={async () => {await skipToStep("Discord",7)}}>skip to step 7</a>. If you have not created a Discord account yet, click the link <a className="" href="https://support.discord.com/hc/en-us/articles/360033931551-Getting-Started" role="button" target={"_blank"}>here</a> for a guide to create a free Discord Account.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds2.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepDiscord" data-value={3}>
                                                <p>3. Type in the email address and password you used to create your Discord Account.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds3.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepDiscord" data-value={4}>
                                                <p>4. Click ‘Log In’.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds4.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepDiscord" data-value={5}>
                                                <p>5. If you have Two-factor authentication enabled, open your 2fa app and enter the code you see in that app. If you do not have 2fa enabled, <a href="#" onClick={async () => {await skipToStep("Discord",7)}}>skip to step 7</a>. As a side note, we HIGHLY recommend you use 2fa as an additional layer of security. <a href="https://support.discord.com/hc/en-us/articles/219576828-Setting-up-Two-Factor-Authentication" target={"_blank"}>Here is a guide</a> Discord created on how to set that up if you are interested.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds5.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepDiscord" data-value={6}>
                                                <p>6. After typing in your 2fa code, click ‘Log In’.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds6.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepDiscord" data-value={7}>
                                                <p>7. Click ‘Accept Invite’ to join the Ethball Discord Server.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds7.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepDiscord" data-value={8}>
                                                <p>8. Please read through rules of the Ethball Discord Server and if you agree to the rules, click the ‘Verify’ button.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds8.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepDiscord" data-value={9}>
                                                <p>9. If you are successful you should see all of the channels in the Ethball Discord Server appear in the left hand ‘Channels’ column.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds9.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                        </div>
                                        <div className="mt-3 d-flex justify-content-around">
                                            <button className="btn btn-primary" id="prevDiscord" onClick={async () => {await previousStep("Discord")}}>Previous Step</button>
                                            <p style={{width: "fit-content"}} className="text-center" id="numDiscord"></p>
                                            <button className="btn btn-primary" id="nextDiscord" onClick={async () => {await nextStep("Discord")}}>Next Step</button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </ul>
                    </li>
                    
                    
                    <li>Link Discord to the Ethball account
                        <ul>
                            <li><a className="" href="#" onClick={async (a) => {a.preventDefault(); openGuideModal(link);}}>Guide to link your Discord account to your Ethball account.</a></li>
                            <div className="collapse" id="collapseLink">
                                <div className="card card-body bg-dark">
                                    <div id="Link" data-value={1}>
                                        <div>
                                            <div className="step stepLink" data-value={1}>
                                                <p>1. On the ethball.io website, click the wallet icon in the upper right hand corner of the website.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld1.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={2}>
                                                <p>2. On the pop-up window, click on ‘Metamask’.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld2.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={3}>
                                                <p>3. The Metamask Web3 Wallet extension should open. Your default account should already be selected. Click ‘Next’ to connect your default account to Ethball.io. (If the Metamask extension menu does not open automatically, look in the upper right hand corner of your browser for the Metamask icon with the notification mark <img src="https://ebimg.ethball.io/demoimg/metamasknotification.png" /> and click that.) If you have logged in to the Ethball.io website with your Metamask wallet previously, you will not see this screen so you can <a href="#" onClick={async () => {await skipToStep("Link",5)}}>jump to step 5</a>.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld3.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={4}>
                                                <p>4. Read the Connection privileges you are agreeing to and hit ‘Connect’.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld4.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={5}>
                                                <p>5. By signing this message, you are agreeing to the Ethball Terms of Service, agreeing that you know and understand how the game works, and that you want to log in to the site. Click ‘Sign’ to accept the terms and log in.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld5.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={6}>
                                                <p>6. You will know that you have successfully logged in as the wallet icon will be replaced with your profile icon (or the default profile icon).</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld6.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={7}>
                                                <p>7. Click the profile icon and select ‘Settings’.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld7.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={8}>
                                                <p>8. Click ‘Link to Discord’.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld8.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={9}>
                                                <p>9. Linking your Discord account will log you out of the Ethball site to complete the connection. Click ‘Yes’ if you want to link your Discord account to your Ethball account right now.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld9.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={10}>
                                                <p>10. If you are not currently logged in to Discord, you will see a screen that looks like this. Click on ‘Already have an account?’. If you are already logged in, you can <a href="#" onClick={async () => {await skipToStep("Link",15)}}>skip to step 15</a>. If you have not created a Discord account yet, click the link <a className="" href="https://support.discord.com/hc/en-us/articles/360033931551-Getting-Started" role="button" target={"_blank"}>here</a> for a guide to create a free Discord Account.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds2.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={11}>
                                                <p>11. Type in the email address and password you used to create your Discord Account.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds3.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={12}>
                                                <p>12. Click ‘Log In’.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds4.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={13}>
                                                <p>13. If you have Two-factor authentication enabled, open your 2fa app and enter the code you see in that app. If you do not have 2fa enabled, <a href="#" onClick={async () => {await skipToStep("Link",15)}}>skip to step 15</a>. As a side note, we HIGHLY recommend you use 2fa as an additional layer of security. <a href="https://support.discord.com/hc/en-us/articles/219576828-Setting-up-Two-Factor-Authentication" target={"_blank"}>Here is a guide</a> Discord created on how to set that up if you are interested.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds5.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={14}>
                                                <p>14. After typing in your 2fa code, click ‘Log In’.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ds6.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={15}>
                                                <p>15. Click ‘Authorize’ to allow Ethball to link to your Discord account.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld10.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={16}>
                                                <p>16. After successfully linking your Discord account to your Ethball account, you will be redirected back to the Ethball website. Click the wallet icon to login to the Ethball website.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld11.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={17}>
                                                <p>17. On the pop-up, click ‘Metamask’</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld12.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={18}>
                                                <p>18. Agree to the terms again and click ‘Sign’ to login.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld13.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={19}>
                                                <p>19. Click the profile icon.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld14.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={20}>
                                                <p>20. Click ‘Settings’.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld15.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepLink" data-value={21}>
                                                <p>21. And if the link from your Discord account to your Ethball account is successful, you should receive this message that your Discord account is linked.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/ld16.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                        </div>
                                        <div className="mt-3 d-flex justify-content-around">
                                            <button className="btn btn-primary" id="prevLink" onClick={async () => {await previousStep("Link")}}>Previous Step</button>
                                            <p style={{width: "fit-content"}} className="text-center" id="numLink"></p>
                                            <button className="btn btn-primary" id="nextLink" onClick={async () => {await nextStep("Link")}}>Next Step</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ul>
                
                    </li>
                    
                    <li>Request some GoerliΞth from the development team to purchase some packs
                        <ul>
                            <li><a className="" href="#" onClick={async (a) => {a.preventDefault(); openGuideModal(goerli);}}>Guide to request Testnet Ethereum (GoerliΞth)</a></li>
                            <div className="collapse" id="collapseGoerli">
                                <div className="card card-body bg-dark" id="Goerli" data-value={1}>
                                    <div>
                                        <div className="step stepGoerli" data-value={1}>
                                            <p className="highlightText">1. In the Ethball Discord Server, click on the ‘help-tickets’ channel</p>
                                            <TransformWrapper>
                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                    <React.Fragment>
                                                    <div className="tools btn-group w-25">
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                    </div>
                                                    <TransformComponent>
                                                        <img src="https://ebimg.ethball.io/demoimg/te1.png" className="step-img" alt="test" />
                                                    </TransformComponent>
                                                    </React.Fragment>
                                                )}
                                            </TransformWrapper>
                                        </div>

                                        <div className="step stepGoerli" data-value={2}>
                                            <p className="highlightText">2. In the ‘help-tickets’ channel, click on ‘Create ticket’</p>
                                            <TransformWrapper>
                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                    <React.Fragment>
                                                    <div className="tools btn-group w-25">
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                    </div>
                                                    <TransformComponent>
                                                        <img src="https://ebimg.ethball.io/demoimg/te2.png" className="step-img" alt="test" />
                                                    </TransformComponent>
                                                    </React.Fragment>
                                                )}
                                            </TransformWrapper>
                                        </div>

                                        <div className="step stepGoerli" data-value={3}>
                                            <p className="highlightText">3. A new ticket should appear under the ‘HELP TICKETS’ header in the channel column on the left. Click on the ticket.</p>
                                            <TransformWrapper>
                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                    <React.Fragment>
                                                    <div className="tools btn-group w-25">
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                    </div>
                                                    <TransformComponent>
                                                        <img src="https://ebimg.ethball.io/demoimg/te3.png" className="step-img" alt="test" />
                                                    </TransformComponent>
                                                    </React.Fragment>
                                                )}
                                            </TransformWrapper>
                                        </div>

                                        <div className="step stepGoerli" data-value={4}>
                                            <p className="highlightText">4. Type a short message to request some testnet GoerliEth. Hit Enter to submit.</p>
                                            <TransformWrapper>
                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                    <React.Fragment>
                                                    <div className="tools btn-group w-25">
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                    </div>
                                                    <TransformComponent>
                                                        <img src="https://ebimg.ethball.io/demoimg/te4.png" className="step-img" alt="test" />
                                                    </TransformComponent>
                                                    </React.Fragment>
                                                )}
                                            </TransformWrapper>
                                        </div>

                                        <div className="step stepGoerli" data-value={5}>
                                            <p className="highlightText">5. Someone from the Ethball team will type a response once some GoerliEth has been sent to you. If we have any questions they will be posted in the Help Ticket as well.</p>
                                            <TransformWrapper>
                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                    <React.Fragment>
                                                    <div className="tools btn-group w-25">
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                    </div>
                                                    <TransformComponent>
                                                        <img src="https://ebimg.ethball.io/demoimg/te5.png" className="step-img" alt="test" />
                                                    </TransformComponent>
                                                    </React.Fragment>
                                                )}
                                            </TransformWrapper>
                                        </div>

                                        <div className="step stepGoerli" data-value={6}>
                                            <p className="highlightText">6. Once you receive the message that we have sent the GoerliEth, you can close the Help Ticket by clicking the ‘Close’ button.</p>
                                            <TransformWrapper>
                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                    <React.Fragment>
                                                    <div className="tools btn-group w-25">
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                    </div>
                                                    <TransformComponent>
                                                        <img src="https://ebimg.ethball.io/demoimg/te6.png" className="step-img" alt="test" />
                                                    </TransformComponent>
                                                    </React.Fragment>
                                                )}
                                            </TransformWrapper>
                                        </div>

                                        <div className="step stepGoerli" data-value={7}>
                                            <p className="highlightText">7. Verify that you want to close the ticket by clicking the red ‘Close’ button.</p>
                                            <TransformWrapper>
                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                    <React.Fragment>
                                                    <div className="tools btn-group w-25">
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                    </div>
                                                    <TransformComponent>
                                                        <img src="https://ebimg.ethball.io/demoimg/te7.png" className="step-img" alt="test" />
                                                    </TransformComponent>
                                                    </React.Fragment>
                                                )}
                                            </TransformWrapper>
                                        </div>

                                        <div className="step stepGoerli" data-value={8}>
                                            <p className="highlightText">8. To verify that you have received your test GoerliEth, click on the Metamask Web3 Browser extension icon in your browser.</p>
                                            <TransformWrapper>
                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                    <React.Fragment>
                                                    <div className="tools btn-group w-25">
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                    </div>
                                                    <TransformComponent>
                                                        <img src="https://ebimg.ethball.io/demoimg/te8.png" className="step-img" alt="test" />
                                                    </TransformComponent>
                                                    </React.Fragment>
                                                )}
                                            </TransformWrapper>
                                        </div>

                                        <div className="step stepGoerli" data-value={9}>
                                            <p className="highlightText">9. Once the Metamask extension menu opens you should see that you now have a balance of 0.5 GoerliEth. If you do not see a balance, make sure you have ‘Goerli test network’ selected. If it is not selected, click the drop down menu at the top of the extension and select ‘Goerli test network’. If you do not see the ‘Goerli test network’ option in your list, check our guide called ‘Guide to install Web3 Wallet Extension on Mac or PC' for instructions on how to show test networks.</p>
                                            <TransformWrapper>
                                                {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                    <React.Fragment>
                                                    <div className="tools btn-group w-25">
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                        <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                    </div>
                                                    <TransformComponent>
                                                        <img src="https://ebimg.ethball.io/demoimg/te9.png" className="step-img" alt="test" />
                                                    </TransformComponent>
                                                    </React.Fragment>
                                                )}
                                            </TransformWrapper>
                                        </div>
                                        
                                    </div>
                                    <div className="mt-3 d-flex justify-content-around">
                                        <button className="btn btn-primary" id="prevGoerli" onClick={async () => {await previousStep("Goerli")}}>Previous Step</button>
                                        <p style={{width: "fit-content"}} className="text-center" id="numGoerli"></p>
                                        <button className="btn btn-primary" id="nextGoerli" onClick={async () => {await nextStep("Goerli")}}>Next Step</button>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </li>

                    <li>Purchase some Ethball Fantasy Baseball Card Packs
                        <ul>
                            <li><a className="" href="#" onClick={async (a) => {a.preventDefault();  openGuideModal(packs);}}>Guide to purchasing Ethball Packs</a></li>
                            <div className="collapse" id="collapsePacks">
                                <div className="card card-body bg-dark">
                                    <div id="Packs" data-value={1}>
                                        <div>
                                            <div className="step stepPacks" data-value={1}>
                                                <p>1. On ethball.io click the wallet icon to login.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp1.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={2}>
                                                <p>2. On the pop-up select the Metamask Web3 Wallet.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp2.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={3}>
                                                <p>3. Agree to the ethball.io terms and click ‘Sign’ to login.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp3.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={4}>
                                                <p>4. On the top navigation menu, click ‘Join’.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp4.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={5}>
                                                <p>5. You can buy up to 5 packs. Click on the + button to add more packs to purchase and - to subtract packs. Once you have the number of packs you would like to buy, click the ‘Buy’ button.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp5.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={6}>
                                                <p>6. This will cause the Metamask Web3 Wallet extension to open. There are several fields worth noting:</p>
                                                <ol className="listHighlight">
                                                    <li>This is the total of all of the packs you have selected multiplied by the price per pack. So if you selected 2 packs and the purchase price is .025 GoerliEth per pack, you would get the total of 0.05 as shown in the image.</li>
                                                    <li>Gas is the name used by the Ethereum blockchain to indicate the cost to make this transaction. Just think of this as a transaction fee to buy the packs.</li>
                                                    <li>The total is the price of the packs + the Gas (or transaction fee).</li>
                                                    <li>If you want to make this purchase, click the ‘Confirm’ button and your transaction will be processed. If you change your mind you can press the ‘Reject’ button instead.</li>
                                                </ol>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp6.png" className="step-img-mobile" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={7}>
                                                <p>7. If you clicked the ‘Confirm’ button you will see a page like this. At this point just wait, the page will automatically update once the transaction completes. In the event it fails you will also receive a message here.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp7.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={8}>
                                                <p>8. The transaction succeeded!</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp8.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={9}>
                                                <p>9. To see your new players and the composites for each player, click on the ‘Manage Team’ menu. Sometimes the Blockchain is running slowly so you may need to be patient. Another option is to log out and log back in a few minutes later.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp9.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={10}>
                                                <p>10. Select the ‘Manage Roster’ option.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp10.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={11}>
                                                <p>11. If this is the first time you are looking at your roster you will first need to join a League. For the beta season there is only one league option - click on the ‘Join Terran League’ button. If you are already in a league you can <a href="#" onClick={async () => {await skipToStep("Packs",13)}}>skip to step 13</a>.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp11.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={12}>
                                                <p>12. Confirm your league selection by clicking the ‘Yes’ button.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp12.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                            <div className="step stepPacks" data-value={13}>
                                                <p>13. Here you can view your roster and the composite players that make up the DNA of your players. You can also see which composite is active on the current day (in bold), which are active tomorrow (in italics), and you can active or deactivate players for the next day with the switch to the far right of each player row.</p>
                                                <TransformWrapper>
                                                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                                        <React.Fragment>
                                                        <div className="tools btn-group w-25">
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                                            <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                                        </div>
                                                        <TransformComponent>
                                                            <img src="https://ebimg.ethball.io/demoimg/bp13.png" className="step-img" alt="test" />
                                                        </TransformComponent>
                                                        </React.Fragment>
                                                    )}
                                                </TransformWrapper>
                                            </div>
                                        </div>
                                        <div className="mt-3 d-flex justify-content-around">
                                            <button className="btn btn-primary" id="prevPacks" onClick={async () => {await previousStep("Packs")}}>Previous Step</button>
                                            <p style={{width: "fit-content"}} className="text-center" id="numPacks"></p>
                                            <button className="btn btn-primary" id="nextPacks" onClick={async () => {await nextStep("Packs")}}>Next Step</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </li>
                    
                    <li>Read through the <Link smooth to="/about#pagetop">Overview</Link> section to learn how to play the game!</li>
                </ol>
                <p>Once you are in the Discord server, open a ticket to request testnet Ethereum. You can do this by opening the #help-tickets channel in the Ethball Discord Server, and click the 'Create ticket' button. We'll provide enough GoerliEth to let you get started purchasing packs!</p>
                <p>We're working on some videos that should clarify many questions that you may have regarding usage of the Web3 Wallet, ethball.io website, OpenSea, etc. In the meantime, if you have any questions, feel free to reach out via Discord or text, and we'll help you as soon as possible!</p>
                <p>Again, many thanks for participating in helping us polish this product. We hope you have fun, and good luck this season!</p>
                <p>-The Ethball Team</p>
            </div>
            <Modal show={showGuideModal} onHide={closeAllModals} contentClassName="text-light bg-dark compositeModal" className={""}>
                <Modal.Header className="bg-dark">
                    <Modal.Title style={{color:"rgb(0, 163, 41)"}}>{}Guide</Modal.Title>
                    <button className="btn btn-danger ms-auto" onClick={async () => {await closeAllModals();}}>Close</button>
                </Modal.Header>
                <Modal.Body>
                    <p>{guideObject[currentStep].num}. {guideObject[currentStep].text}</p>
                    {guideObject[currentStep].extra && (
                        <div>{guideObject[currentStep].extra}</div>
                    )}
                    <TransformWrapper>
                            {({ zoomIn, zoomOut, resetTransform }) => (
                            <React.Fragment>
                                <div className="tools btn-group w-25">
                                    <button className="btn btn-primary border border-warning" onClick={() => zoomIn()}>Zoom In</button>
                                    <button className="btn btn-primary border border-warning" onClick={() => zoomOut()}>Zoom Out</button>
                                    <button className="btn btn-primary border border-warning" onClick={() => resetTransform()}>Reset</button>
                                </div>
                                <TransformComponent>
                                    <img src={"https://ebimg.ethball.io/demoimg/" + guideObject[currentStep].img} id="stepimg" className="" alt="test" />
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100 justify-content-around">
                        <button disabled={prevInactive} className="btn btn-primary" onClick={async () => {await previousStep();}}>Previous</button>

                        <span>{currentStep+1}/{maxSteps}</span>

                        <button disabled={nextInactive} className="btn btn-primary" onClick={async () => {await nextStep();}}>Next</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    </div>
  );
};

export default Beta;